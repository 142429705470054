import TaskOutlined from "@mui/icons-material/TaskOutlined";
import ContentPasteOutlined from "@mui/icons-material/ContentPasteOutlined";


const tasks = {
    home: {
        title: `مدیریت وظایف`,
        Icon: TaskOutlined,
        path: `/tasks`,
        keywords: [],
    },
    list: {
        title: `بردهای وظایف`,
        Icon: TaskOutlined,
        path: `/tasks/boards`,
        keywords: [],
    },
    boardDetails: (board) => ({
        title: board?.name,
        Icon: ContentPasteOutlined,
        path: '/tasks/board/'.concat(board?.id)
    })
}

export default tasks;