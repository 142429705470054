import { Icon as Iconify } from "@iconify/react";
import ExpandMore from "@mui/icons-material/ExpandMoreOutlined";
import { Box, Button, ButtonBase, Divider, Grid, IconButton, Popover, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";


const WorkspaceButton = styled(ButtonBase, {
    shouldForwardProp: prop => prop !== 'background'
})(({ theme, background }) => ({
    '& .wsOverlay-root': {
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.7rem',
        marginTop: 'auto',
        width: '100%',
        padding: theme.spacing(1),
        color: 'rgba(255,255,255,0.8)',
        backgroundColor: "rgba(0,0,0,0.3)",
        flexDirection: 'column',
        alignItems: 'flex-start',
        display: 'flex'
    },
    height: 100,
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    ...(String(background).startsWith('url') ? {
        backgroundImage: background
    } : { background })
}))


const WorkspacesMenu = () => {

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget)

    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl);
    const id = open ? 'workspaces-menu' : undefined;

    const middleware = useMiddleware('core.authenticated');

    const [isLoading, setLoading] = useState(true);
    const [workspaces, setWorkspaces] = useState([]);

    const { workspace } = useAuth();

    var mounted = useRef(false);

    useEffect(() => {
        const loadWorkspacesAsync = async () => {
            const response = await middleware.workspaces();
            if (response.ok) {
                setWorkspaces(response.data);
                setLoading(false);
            }
        }

        if(!mounted.current) {
            loadWorkspacesAsync();
            mounted.current = true;
            return;
        }

        if (open) loadWorkspacesAsync();
    }, [open])

    const setAsCurrent = async (workspaceId) => {
        const response = await middleware.setAsCurrent(workspaceId);
        if (response.ok) {
            window.location.href = '/';
        }
    }


    if(workspaces.length < 2) {
        return null;
    }

    return (
        <>
            {isMobile ? (
                <IconButton
                    color="default"
                    onClick={handleClick}
                >
                    <Iconify icon="solar:logout-line-duotone" />
                </IconButton>
            ): (
                <Button
                    variant="text"
                    color="inherit"
                    onClick={handleClick}
                    endIcon={<ExpandMore />}
                >
                    <strong>{workspace.name || 'فضای کار بدون نام'}</strong>
                </Button>
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        minWidth: 400
                    }
                }}
            >
                <Box sx={{ display: 'flex', px: 2, py: 1, alignItems: 'center' }}>
                    <Typography variant="button" sx={{ fontSize: 14 }}>فضاهای کار</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {/* {workspaces.length > 0 && (
                        <Button
                            size="small"
                            startIcon={<DoneAll />}
                            onClick={markAllRead}
                            disabled={isMarkingAll}
                        >
                            خواندن همه
                        </Button>
                    )} */}
                </Box>
                <Divider />
                {workspaces.length > 0 ? (
                    <Box sx={{ p: 2 }}>
                        <Grid container columns={2} spacing={1}>
                            {workspaces.map((workspace) => (
                                <Grid item xs={1} key={workspace.id}>
                                    <WorkspaceButton
                                        onClick={() => setAsCurrent(workspace.id)}
                                        background={workspace.membership.options['background']}
                                    >
                                        <Box className="wsOverlay-root">
                                            {/* <span style={{ float: 'right' }}>{workspace.membership.roles.map(r => r.name).join(', ')}</span> */}
                                            <strong>{workspace.name}</strong>
                                            {workspace.category && <span>{'فضای کار '.concat(workspace.category.name)}</span>}
                                        </Box>
                                    </WorkspaceButton>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : (
                    <Box sx={{ py: 5, textAlign: "center" }}>
                        <p>فضای کاری وجود ندارد.</p>
                    </Box>
                )}
            </Popover>
        </>
    )
}

export default WorkspacesMenu;