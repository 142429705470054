import Error from "@mui/icons-material/ErrorOutline";
import { Container, Paper, Typography } from "@mui/material";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { TabView } from "../tabs";

const Unauthorized = ({
    title = "404",
    Icon = Error,
    message = "صفحه موردنظر یافت نشد"
}) => {
    return (
        <TabView title={title} Icon={Icon}>
            <ResponsiveContainer>
                <Container disableGutters>
                    <Paper
                        sx={{
                            py: 15,
                            textAlign: 'center',
                        }}
                    >
                        <Icon fontSize="inherit" sx={{ mb: 2, fontSize: 50 }} />
                        <Typography variant="h6">{message}</Typography>
                    </Paper>
                </Container>
            </ResponsiveContainer>
        </TabView>
    )
}

export default Unauthorized;