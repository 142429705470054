import Middleware from "../../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class ClientsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Clients';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        invoices: '/client/:clientId/invoices',
    }

    /**
     * Get the clients invoices
     *  
     * @return Promise
     */
    async invoices(clientId, args) {
        try {
            const response = await axios(
                this.serializePath(
                    this.replaceParams(this.paths.invoices, { clientId }),
                    args
                )
            );
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get the client latest invoices
     *  
     * @return Promise
     */
    async latestInvoice(clientId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.invoices, { clientId })
            );
            return {
                ...response.data,
                data: response.data.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default ClientsMiddleware;