import { lazy } from 'react';
import Loadable from '../../components/Loadable';


const Boards = Loadable(lazy(() => import('./pages/Boards/Main')));
const BoardsHome = lazy(() => import('./pages/Boards/Home'));
const BoardsView = lazy(() => import('./pages/Boards/Boards'));

const BoardDetails = Loadable(lazy(() => import('./pages/BoardDetails/Main')));
const BoardLists = lazy(() => import('./pages/BoardDetails/Lists'));
const BoardSettings = lazy(() => import('./pages/BoardDetails/Settings'));


const routes = (module) => {
	const homeInjections = module.inject('routes.boards.home');
	const detailsInjections = module.inject('routes.boards.details');

	
	return [
		{
			variant: "dashboard",
			private: true,
			path: '/tasks',
			element: <Boards />,
			children: [
				{ variant: "dashboard", private: true, index: true, element: <BoardsHome /> },
				{ variant: "dashboard", private: true, path: "boards", element: <BoardsView /> },
				...(homeInjections ? homeInjections : [])
			],
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole('owner') || hasPermission('boards:view');
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/tasks/board/:boardId',
			element: <BoardDetails />,
			children: [
				{ variant: "dashboard", private: true, index: true, element: <BoardLists /> },
				{ variant: "dashboard", private: true, path: "settings", element: <BoardSettings /> },
				...(detailsInjections ? detailsInjections : [])
			],
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole('owner') || hasPermission('boards:view');
			}
		}
	]
};

export default routes;