import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
    // .use(LanguageDetector)
    .use(initReactI18next)
    .use(resourcesToBackend((language, namespace) => {
        if (namespace === "global")
            return import(`./translations/${language}.js`);
        return import(`./modules/${namespace}/translations/${language}.js`);
    }))
    .init({
        // keySeparator: false,
        lng: 'fa',
        fallbackLng: 'en',
        react: {
            useSuspense: true
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;