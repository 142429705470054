import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Text from "../components/Text";
import format from "date-fns-jalali/format";

const DateTime = () => {

    const [date, setDate] = useState(new Date());

    const refreshClock = () => setDate(new Date());

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return () => clearInterval(timerId);
    }, []);


    return (
        <Button
            color="inherit"
            size="small"
            sx={{ lineHeight: 1.25 }}
        >
            <Text>{format(date, 'HH:mm')}</Text>
            <br />
            <Text>{format(date, 'dd MMMM yyyy')}</Text>
        </Button>
    )
}

export default DateTime;