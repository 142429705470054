import {
    Link as MuiLink,
    styled, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import pricify from "../../../../utils/pricify";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
}));

const InvoiceShipments = ({
    invoice
}) => {

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <TableContainer>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell>راننده</TableCell>
                        <TableCell align="center">مبلغ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoice.shipments.map((shipment, idx) => (
                        <StyledTableRow key={idx}>
                            <TableCell align="center">{idx + 1}</TableCell>
                            <TableCell>
                                {shipment.driver && (
                                    <MuiLink
                                        color="inherit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(
                                                '/client/'.concat(shipment.driver.id),
                                                { state: { prev: location } }
                                            )
                                        }}
                                    >
                                        {shipment.driver.fullName}
                                    </MuiLink>
                                )}
                            </TableCell>
                            <TableCell align="center">{pricify(shipment.price)}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InvoiceShipments