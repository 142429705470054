import NoProductIcon from "@mui/icons-material/Warning";
import {
    Box, Link, styled, Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMiddleware, useTask } from "../../../../../Application";
import DetailedDate from "../../../../../components/DetailedDate";
import { TabLink } from "../../../../../tabs";
import pricify from "../../../../../utils/pricify";
import useGetRowNumber from "../../../../../utils/useGetRowNumber";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        color: 'red'
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const InvoicesTable = () => {

    const middleware = useMiddleware('ceremonies/reservation.clients')

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const [invoices, setInvoices] = useState({
        loading: true,
        data: [],
        total: ''
    });
    const [page, setPage] = useState(0);

    const _loadInvoicesAsync = async () => {
        const { ok, data, total } = await middleware.invoices(params.clientId, { page });
        if (ok) {
            setInvoices({
                loading: false,
                data,
                total
            });
        }
    }

    useEffect(() => {
        _loadInvoicesAsync();
    }, [params.clientId])

    const taskInvoiceDetails = useTask('invoices.details')

    const getRowNumber = useGetRowNumber();

    if (invoices.data.length < 1)
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <NoProductIcon fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )

    return (
        <Box>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ردیف</TableCell>
                            <TableCell align="center">صورت‌حساب</TableCell>
                            <TableCell align="center">تاریخ رزرو</TableCell>
                            <TableCell align="center">مجموع</TableCell>
                            <TableCell align="center">تاریخ برگشت</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.data.map((invoice, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    <TableCell align="center">{getRowNumber(index)}</TableCell>
                                    <TableCell align="center">
                                        <Link
                                            color="inherit"
                                            onClick={() => navigate(
                                                "/invoice/".concat(invoice.id),
                                                { state: { prev: location } }
                                            )}
                                        >
                                            {invoice.number}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center"><DetailedDate date={invoice.reservedAt} /></TableCell>
                                    <TableCell align="center">{pricify(invoice.total)}</TableCell>
                                    <TableCell align="center">{invoice.isReturned ? <DetailedDate date={invoice.returnedAt} /> : '-'}</TableCell>
                                </StyledTableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={invoices.total}
                rowsPerPage={25}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={setPage}
                labelDisplayedRows={({ from, to, count }) => `${from}–${to} از مجموع ${count !== -1 ? count : `بیشتر از ${to}`} ردیف`}
            />
        </Box>
    )
}

export default InvoicesTable;