import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogTitle,
    Divider
} from "@mui/material";
import forOwn from "lodash/forOwn";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMiddleware } from "../../../Application";
import TextField from "../../../components/TextField";
import { useSnackbar } from 'notistack';


const CategoryFormDialog = ({
    open,
    onClose,
    label,
    category,
    onCreateOrUpdate
}) => {

    const middleware = useMiddleware('categories.main')

    const defaultValues = {
        name: ''
    }

    const { register, handleSubmit, formState: { errors }, reset, setError } = useForm({
        defaultValues
    });


    const [isCreatingOrUpdating, setCreatingOrUpdating] = useState(false);

    useEffect(() => {
        if (category) reset(category)
    }, [category]);

    useEffect(() => {
        if (!open) {
            const timer = setTimeout(() => {
                reset(defaultValues);
            }, 500);

            return () => {
                clearTimeout(timer);
            }
        }
    }, [open])


    const { enqueueSnackbar } = useSnackbar();

    const _createOrUpdateAsync = async (data) => {
        setCreatingOrUpdating(true);
        const response = await middleware.createOrUpdate({ ...data, label });

        if (response.ok) {
            enqueueSnackbar(`گروه ${category ? 'بروزرسانی' : 'جدید ثبت'} گردید.`);
            onClose()
            onCreateOrUpdate(response.category)
            setCreatingOrUpdating(false)
            reset(defaultValues)
        } else {
            const { status, errors } = response;
            if (status === 422) {
                forOwn(errors, (value, key) => {
                    setError(key, { type: "manual", message: value[0] })
                })
                setCreatingOrUpdating(false);
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>
                {category ? 'بروزرسانی گروه' : 'ثبت گروه جدید'}
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    size="small"
                    label="نام گروه"
                    variant="filled"
                    required
                    autoFocus
                    error={errors.name && true}
                    helperText={errors.name?.message}
                    {...register("name", { required: true })}
                />
            </Box>
            <Divider />
            <DialogActions>
                <LoadingButton
                    loading={isCreatingOrUpdating}
                    loadingPosition="start"
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit(_createOrUpdateAsync)}
                >
                    ثبت
                </LoadingButton>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CategoryFormDialog