import HistoryIcon from "@mui/icons-material/ViewTimelineOutlined";
import { IconButton, TableCell, TableSortLabel, Tooltip } from "@mui/material";
import { Fragment } from "react";
import pricify from "../../../../utils/pricify";
import useDialog from "../../../../utils/useDialog";
import ProductInvoicesDialog from "../components/ProductInvoicesDialog";


export const ProductsTableHeadCells = ({ sort, setSort }) => {
    return (
        <Fragment>
            <TableCell align="center">
                <TableSortLabel
                    active={sort.includes('unitPrice')}
                    direction={sort.startsWith('-') ? 'desc' : 'asc'}
                    onClick={() => setSort(sort === "unitPrice" ? "-unitPrice" : "unitPrice")}
                >
                    فی کرایه <small>(ریال)</small>
                </TableSortLabel>
            </TableCell>
            <TableCell align="center">
                <TableSortLabel
                    active={sort.includes('damagePrice')}
                    direction={sort.startsWith('-') ? 'desc' : 'asc'}
                    onClick={() => setSort(sort === "damagePrice" ? "-damagePrice" : "damagePrice")}
                >
                    فی خسارت <small>(ریال)</small>
                </TableSortLabel>
            </TableCell>
            <TableCell align="center">موجودی کل</TableCell>
            <TableCell align="center">مجموع سفارشات</TableCell>
        </Fragment>
    )
}

export const ProductsTableBodyCells = ({ product }) => {
    return (
        <Fragment>
            <TableCell align="center">{pricify(product.unitPrice)}</TableCell>
            <TableCell align="center">{pricify(product.damagePrice)}</TableCell>
            <TableCell align="center">{product.totalQuantity}</TableCell>
            <TableCell align="center">{product.invoices.length}</TableCell>
        </Fragment>
    )
}

export const ProductsTableActions = ({ product }) => {
    const {
        isOpen: isInvoicesDialogOpen,
        open: openInvoicesDialog,
        close: closeInvoicesDialog,
        data: invoicesDialogProduct
    } = useDialog();

    return (
        <Fragment>
            <Tooltip title="تاریخچه سفارشات">
                <IconButton
                    size="small"
                    onClick={() => openInvoicesDialog(product)}
                >
                    <HistoryIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            <ProductInvoicesDialog
                open={isInvoicesDialogOpen}
                onClose={closeInvoicesDialog}
                product={invoicesDialogProduct}
            />
        </Fragment>
    )
}