import { Icon as Iconify } from "@iconify/react";


const tasks = {
    manage: {
        title: `مدیریت اشخاص`,
        Icon: props => <Iconify {...props} icon='solar:users-group-rounded-bold-duotone' />,
        path: `/people`,
        keywords: [],
    },
    details: (person) => ({
        title: person?.fullName,
        Icon: props => <Iconify {...props} icon='solar:user-rounded-bold-duotone' />,
        path: '/people/'.concat(person?.id)
    })
}

export default tasks;