/* eslint-disable react-hooks/rules-of-hooks */
import { Avatar, Skeleton } from "@mui/material";


const people = {
    'PersonDetails.TabView': ({ loading, person }) => ({
        renderIcon: ({ sx, props }) => (
            <Avatar
                {...props}
                alt={person?.fullName}
                src={person?.avatar}
                sx={{ ...sx, width: 30, height: 30 }}
            />
        ),
    }),
    'PersonDetails.PreHeader': ({ loading, person }) => {
        return loading ? (
            <Skeleton
                width={50}
                height={50}
                variant="circular"
                sx={{ mr: 2 }}
            />
        ) : (
            <Avatar
                src={person.avatar}
                sx={{ mr: 2, width: 50, height: 50 }}
            />
        )
    }
}

export default people;