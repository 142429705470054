/* eslint-disable react-hooks/rules-of-hooks */
import CategoryOutlined from "@mui/icons-material/CategoryOutlined";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Fragment } from "react";
import useDialogProps from "../../../utils/useDialogProps";
import CategoriesDialog from "../components/CategoriesDialog";

import People from "./people";
import Products from "./products";


const hooks = {
    People,
    Products,
    'Lawyers/Case-Management': {
        'CasesList.Hooks': injectedParams => ({
            'categoriesFormHook': useDialogProps("categories")
        }),
        'CasesList.ActionsList': ({ categoriesFormHook }) => {
            const [categoriesDialogProps, openCategoriesDialog, closeCategoriesDialog] = categoriesFormHook;

            return {
                title: 'مدیریت گروه‌ها',
                icon: <CategoryOutlined fontSize="inherit" />,
                onClick: () => openCategoriesDialog()
            }
        },
        'CasesList.Footer': ({ categoriesFormHook, key }) => {
            const [categoriesDialogProps, openCategoriesDialog, closeCategoriesDialog] = categoriesFormHook;

            return (
                <Fragment key={key}>
                    <CategoriesDialog
                        {...categoriesDialogProps}
                        label="case"
                    />
                </Fragment>
            )
        },
        'CaseForm.Hooks': injectedParams => ({
            'categoriesDialogHook': useDialogProps()
        }),
        'CaseForm.PostForm': ({ getValue, categoriesDialogHook, key }) => {
            const [categoriesDialogProps, openCategoriesDialog, closeCategoriesDialog] = categoriesDialogHook;

            return (
                <Fragment key={key}>
                    <Divider />
                    <List disablePadding dense>
                        <ListItem disablePadding dense>
                            <ListItemButton onClick={() => openCategoriesDialog()}>
                                <ListItemIcon>
                                    <CategoryOutlined color="inherit" />
                                </ListItemIcon>
                                <ListItemText
                                    primary={getValue('categories') && getValue('categories').length > 0 ? getValue('categories').map(r => r.name).join(', ') : <small>برای انتخاب کلیک کنید</small>}
                                    secondary="گروه‌ها"
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Fragment>
            )
        },
        'CaseForm.Footer': ({ getValue, setValue, categoriesDialogHook, key }) => {
            const [categoriesDialogProps, openCategoriesDialog, closeCategoriesDialog] = categoriesDialogHook;

            return (
                <Fragment key={key}>
                    <CategoriesDialog
                        {...categoriesDialogProps}
                        selectable
                        multiple
                        selected={getValue("categories") || []}
                        onClear={() => setValue("categories", [])}
                        label="case"
                        onSelect={cat => setValue(
                            "categories",
                            getValue("categories").filter(c => c.id === cat.id).length > 0 ?
                                getValue("categories").filter(c => c.id !== cat.id) :
                                [...getValue("categories"), cat]
                        )}
                    />
                </Fragment>
            )
        },
    }
};

export default hooks;