import { Edit } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import InvoiceDeliverConfirmation from "../components/InvoiceDeliverConfirmation";
import InvoiceReturnDialog from "../components/InvoiceReturnDialog";
import ClientInvoicesTable from "../pages/ClientDetails/InvoicesTable";
import { ClientDetailsOverview } from "./ClientDetails";
import { InvoiceReserveOverview } from "./InvoiceDetails";
import ProductFormDialogFields from "./ProductFormDialogFields";
import { ProductSelectDialogBodyCells, ProductSelectDialogHeadCells } from "./ProductSelectDialog";
import { ProductsTableActions, ProductsTableBodyCells, ProductsTableHeadCells } from "./ProductsTable";


const hooks = {
    'Products': {
        'ProductFormDialog.defaultValues': () => {
            return {
                reserveUnitPrice: '',
                returnDamagePrice: '',
                totalQuantity: '',
            }
        },
        'ProductFormDialog.Fields': props => <ProductFormDialogFields {...props} />,
        'ProductsTable.HeadCells': props => <ProductsTableHeadCells {...props} />,
        'ProductsTable.BodyCells': props => <ProductsTableBodyCells {...props} />,
        'ProductsTable.Actions': props => <ProductsTableActions {...props} />,
        'ProductSelectDialog.getEffectDeps': (injectionParams) => {
            return injectionParams.reservedAt;
        },
        'ProductSelectDialog.HeadCells': props => <ProductSelectDialogHeadCells {...props} />,
        'ProductSelectDialog.BodyCells': props => <ProductSelectDialogBodyCells {...props} />,
    },
    'Clients': {
        'ClientFormDialog.roles': () => {
            return {
                name: 'مشتری',
                value: 'customer'
            }
        },
        'ClientDetails.TabsList': ({ clientId }) => {
            return { label: 'تاریخچه رزرو', value: `/client/${clientId}/invoices` }
        },
        'ClientDetails.Overview.Grid': (props) => <ClientDetailsOverview {...props} />,
        'routes.clients.details': () => {
            return { variant: "dashboard", requireAuth: true, path: 'invoices', element: <ClientInvoicesTable /> }
        },
    },
    'Invoices': {
        'InvoiceDetails.State': () => {
            return {
                deliverDialogOpen: false,
                returnDialogOpen: false
            }
        },
        'InvoiceDetails.PreSide': props => <InvoiceReserveOverview {...props} />,
        'InvoiceDetails.PostFooter': ({ invoice, state, setState, refetch }) => {
            return (
                <>
                    <InvoiceDeliverConfirmation
                        open={state.deliverDialogOpen}
                        onClose={() => setState({ ...state, deliverDialogOpen: false })}
                        invoice={invoice}
                        onDeliver={invoice => refetch()}
                    />

                    <InvoiceReturnDialog
                        open={state.returnDialogOpen}
                        onClose={() => setState({ ...state, returnDialogOpen: false })}
                        invoice={invoice}
                        onDeliver={invoice => refetch()}
                    />
                </>
            )
        },
        'InvoiceDetails.Menu': props => <MenuItemEdit {...props} />
    }
};

const MenuItemEdit = ({ invoice, menuProps }) => {

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <MenuItem
            onClick={() => {
                navigate(
                    `/invoice/${invoice.id}/edit`,
                    { state: { prev: location } }
                );
                menuProps.setAnchorEl(null);
            }}
        >
            <ListItemIcon>
                <Edit />
            </ListItemIcon>
            <ListItemText>ویرایش صورت‌حساب</ListItemText>
        </MenuItem>
    )
}

export default hooks;