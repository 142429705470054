import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const Dashboard = Loadable(lazy(() => import('./pages/Dashboard/Main')));
const BookDetails = Loadable(lazy(() => import('./pages/BookDetails/BookDetails')));
const BookOverview = lazy(() => import('./pages/BookDetails/BookOverview'));
const BookView = lazy(() => import('./pages/BookDetails/BookView'));


const routes = (module) => {
	return [
		{
			variant: "dashboard",
			private: true,
			path: '/docs',
			element: <Dashboard />,
			authorize: ({ hasRole, hasPermission }) => {
				return true;
				// return hasRole("owner") || hasPermission("person:view");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/docs/:bookId',
			element: <BookDetails />,
			children: [
				{ variant: "dashboard", private: true, index: true, element: <BookView /> },
				{ variant: "dashboard", private: true, path: "info", element: <BookOverview /> },
				...(module.inject('routes.books.details') || [])
			],
			authorize: ({ hasRole, hasPermission }) => {
				return true;
				// return hasRole("owner") || hasPermission("person:view");
			}
		}
	]
};

export default routes;