import { lazy } from "react";
import Loadable from "../../components/Loadable";

const Manage = Loadable(lazy(() => import('./pages/Manage/Main')));
const History = Loadable(lazy(() => import('./pages/History/Main')));


const routes = [
    {
        variant: "dashboard",
        path: '/sms/manage',
        element: <Manage />,
        private: true,
        authorize: ({ hasRole, hasPermission }) => {
            return hasRole("owner") || hasPermission("sms:view");
        }
    },
    {
        variant: "dashboard",
        path: '/sms/history',
        element: <History />,
        private: true,
        authorize: ({ hasRole, hasPermission }) => {
            return hasRole("owner") || hasPermission("sms:view");
        }
    },

    //
];

export default routes;