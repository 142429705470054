import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware base path
     * 
     * @var string
     */
    basePath = '/sms';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/history',
    }

    /**
     * Get list of files match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args, config) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args), config);
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;