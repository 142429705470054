import Delete from "@mui/icons-material/DeleteOutlined";
import { useState } from "react";
import { useMiddleware } from "../../../Application";
import ConfirmDialog from "../../../components/ConfirmDialog";


const CategoryDeleteDialog = ({
    category,
    onDelete,
    requestConfig,
    ...confirmProps
}) => {

    const middleware = useMiddleware('categories.main');

    const [loading, setLoading] = useState(false);

    const deleteAsync = async () => {
        setLoading(true);
        try {
            const { ok } = await middleware.delete(category.id, requestConfig);
            if (ok) {
                setLoading(false);
                onDelete();
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <ConfirmDialog
            {...confirmProps}
            title={`حدف گروه برای همیشه؟`}
            content={`گروه برای همیشه حذف گردد؟`}
            onConfirm={deleteAsync}
            confirmButtonProps={{ color: 'error' }}
            cancelButtonProps={{ autoFocus: true }}
            confirmText="تایید و حذف"
            loading={loading}
            confirmIcon={<Delete />}
        />
    )
}

export default CategoryDeleteDialog