import ManageIcon from "@mui/icons-material/SmsOutlined";
import { Icon as Iconify } from "@iconify/react";

const tasks = {
    manage: {
        title: `مدیریت سامانه پیامکی`,
        Icon: ManageIcon,
        path: `/sms/manage`,
        subtasks: []
    },
    history: {
        title: `پیامک‌های ارسال‌شده`,
        Icon: props => <Iconify {...props} icon='solar:history-bold-duotone' />,
        path: `/sms/history`,
        subtasks: []
    },
    //
}

export default tasks;