import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class BooksMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Books';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/docs/books';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        createOrUpdate: '/',
        details: '/:bookId',
        delete: '/:bookId',
    }

    /**
     * Get list of persons match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args));
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing person
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }

    /**
     * Get person details
     * 
     * @param string bookId 
     * @return Promise 
     */
    async details(bookId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.details, { bookId })
            );
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an exsisting person
     * 
     * @param string bookId 
     * @return Promise 
     */
    async delete(bookId) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { bookId })
            });
            return response.data;
        } catch (error) {
            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }
}

export default BooksMiddleware;