import { Avatar, styled } from "@mui/material";
import { useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";
import LazyImageComponent from "../../../components/LazyImage";


import IconDefault from "@mui/icons-material/FileCopyOutlined";
import IconImage from "@mui/icons-material/ImageOutlined";
import IconVideo from "@mui/icons-material/VideoFileOutlined";
import IconAudio from "@mui/icons-material/AudioFileOutlined";
import IconText from "@mui/icons-material/DescriptionOutlined";
import IconFont from "@mui/icons-material/FormatSizeOutlined";
import IconZip from "@mui/icons-material/FolderZipOutlined";
import IconPdf from "@mui/icons-material/PictureAsPdfOutlined";
import IconWord from "@mui/icons-material/ArticleOutlined";
import IconExcel from "@mui/icons-material/TableViewOutlined";
import IconPowerPoint from "@mui/icons-material/SlideshowOutlined";
import IconInstaller from "@mui/icons-material/SystemUpdateOutlined";


const LazyImage = styled(LazyImageComponent)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius
}))

const FilePreviwIcon = ({ file, DefaultIcon, iconProps = { fontSize: "large" } }) => {

    const middleware = useMiddleware('Storage.Main');
    const { user, workspace, getAuthToken } = useAuth();

    const isImage = _file => Boolean(_file.mime && _file.mime.startsWith('image/'));

    const renderMimeAvatar = (mime) => {

        if (!mime)
            return DefaultIcon || <IconDefault {...iconProps} />;

        // general image
        if (mime.startsWith('image/'))
            return <IconImage {...iconProps} />;

        // general video
        if (mime.startsWith('video/'))
            return <IconVideo {...iconProps} />;

        // general audio
        if (mime.startsWith('audio/'))
            return <IconAudio {...iconProps} />;

        // general text
        if (mime.startsWith('text/'))
            return <IconText {...iconProps} />;

        // general font
        if (mime.startsWith('font/'))
            return <IconFont {...iconProps} />;

        // zip files
        if ([
            'application/zip',
            'application/x-7z-compressed',
            'application/x-tar',
            'application/vnd.rar',
            'application/gzip',
            'application/x-bzip',
            'application/x-bzip2'
        ].includes(mime))
            return <IconZip {...iconProps} />;

        // pdf files
        if ([
            'application/vnd.amazon.ebook',
            'application/epub+zip',
            'application/pdf'
        ].includes(mime))
            return <IconPdf {...iconProps} />;

        // office word files
        if ([
            'application/x-abiword',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(mime))
            return <IconWord {...iconProps} />;

        // office excel files
        if ([
            'text/csv',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ].includes(mime))
            return <IconExcel {...iconProps} />;

        // office power point files
        if ([
            'application/vnd.oasis.opendocument.presentation',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ].includes(mime))
            return <IconPowerPoint {...iconProps} />;

        // installer files
        if ([
            'application/vnd.apple.installer+xml',
            'application/vnd.android.package-archive'
        ].includes(mime))
            return <IconInstaller {...iconProps} />;

        // default
        return DefaultIcon || <IconDefault {...iconProps} />;
    }

    if (isImage(file)) {
        return (
            <LazyImage
                width={40}
                height={40}
                src={middleware.getDirectDownloadUrl(file.id, getAuthToken(), workspace.id)}
            />
        )
    }

    return (
        <Avatar
            variant="rounded"
            sx={{ backgroundColor: 'transparent', color: 'inherit' }}
        >
            {renderMimeAvatar(file.mime)}
        </Avatar>
    )
}

export default FilePreviwIcon;