import TasksMiddleware from "./TasksMiddleware";
import ConditionsMiddleware from "./ConditionsMiddleware";
import TriggersMiddleware from "./TriggersMiddleware";


const middlewares = [
    TasksMiddleware,
    ConditionsMiddleware,
    TriggersMiddleware,
];

export default middlewares;