import CloseIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import NoResultItem from "@mui/icons-material/Warning";
import {
    Box,
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogTitle, Divider, IconButton, InputAdornment, styled, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMiddleware } from "../../../Application";
import TextField from "../../../components/TextField";
import useDebounce from '../../../utils/useDebounce';


const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
    cursor: 'pointer',
    backgroundColor: selected ? theme.palette.action.hover : theme.palette.common.white,
    '&:focus': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const SamplesTable = ({ loading, samples, selected, onSelect }) => {

    if (loading) {
        return (
            <Box sx={{ px: 5, py: 15, textAlign: "center" }}>
                <CircularProgress />
            </Box>
        )
    }

    if (samples.length < 1) {
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <NoResultItem fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )
    }


    return (
        <TableContainer
            sx={{ overflow: "auto", maxHeight: 400 }}
        >
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">ردیف</TableCell>
                        <TableCell>نام</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {samples.map((sample, index) => (
                        <StyledTableRow
                            key={index}
                            selected={selected && selected.id === sample.id}
                            onClick={() => onSelect(sample)}
                            hover
                        >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell>{sample.name}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


const ContractSampleSelectDialog = ({
    open,
    onClose,
    selected,
    onSelect,
    onClear
}) => {

    const middleware = useMiddleware('Contract.Samples');

    const [loading, setLoading] = useState(true);
    const [samples, setSamples] = useState([]);

    const [query, setQuery] = useState('');


    const debouncedQuery = useDebounce(query, 500);

    const loadSamplesAsync = async () => {
        // setLoading(true);
        const response = await middleware.list({
            query: debouncedQuery,
            ...(query === '' ? {
                page: 0,
                perPage: 100
            } : {})
        });
        if (response.ok) {
            setSamples(response.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open) {
            loadSamplesAsync();
        } else {
            const timer = setTimeout(() => {
                setLoading(true);
                if (query !== '') setQuery('');
            }, 250)

            return () => {
                clearTimeout(timer)
            }
        }
    }, [open, debouncedQuery]);


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>انتخاب نمونه قرارداد</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    size="small"
                    label="نام نمونه قرارداد"
                    variant="filled"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    autoFocus
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                    }}
                />
            </Box>
            <SamplesTable
                loading={loading}
                samples={samples}
                selected={selected}
                onSelect={onSelect}
            />
            <Divider />
            <DialogActions>
                {onClear && <Button type="button" onClick={onClear}>پاک کردن</Button>}
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>
        </Dialog>
    )
}

export default ContractSampleSelectDialog