import Middleware from "../../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/cases',
        getNewCode: '/cases/new/code',
        createOrUpdate: '/cases',
        details: '/cases/:caseId',
        delete: '/cases/:caseId',
    }

    /**
     * Get list of cases match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args));
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get new code for new creating client
     *  
     * @return Promise
     */
    async getNewCode() {
        try {
            const response = await axios(this.paths.getNewCode);
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing client
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }

    /**
     * Get client details
     * 
     * @param string caseId 
     * @return Promise 
     */
    async details(caseId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.details, { caseId })
            );
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an exsisting client
     * 
     * @param string caseId 
     * @return Promise 
     */
    async delete(caseId) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { caseId })
            });
            return response.data;
        } catch (error) {
            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;