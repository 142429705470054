import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMiddleware } from "../../../../Application";


const InvoiceDeliverConfirmation = ({ open, onClose, onDeliver, invoice }) => {

    const { enqueueSnackbar } = useSnackbar();

    const middleware = useMiddleware('ceremonies/reservation.invoices');

    const deliverInvoiceAsync = async () => {
        const { ok } = await middleware.deliver(invoice.id);
        if (ok) {
            enqueueSnackbar('صورت‌حساب تحویل داده شد.');
            onClose();
            onDeliver(invoice);
        }
    }

    if (!invoice) {
        return (null);
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="alert-dialog-title">
                تایید تحویل
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {[
                        'تایید تحویل صورت‌حساب',
                        `شماره ${invoice.number}`,
                        invoice.client && `بنام ${invoice.client?.fullName}`
                    ].filter(Boolean).join(' ')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={deliverInvoiceAsync} variant="contained" color="primary" autoFocus>ثبت تحویل</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={onClose}>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvoiceDeliverConfirmation