import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, Fade, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import Routes from './Routes';
import { AuthProvider } from "./auth";
import ErrorBoundary from './components/ErrorBoundary';
import ReportComplete from "./components/Snackbar/ReportComplete";
import UploadFile from "./components/Snackbar/UploadFile";
import LocalStorageProvider from "./localStorage/LocalStorageProvider";
import defaultTheme from "./themes/default";


const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin]
});

const Container = () => {
    return (
        <ErrorBoundary>
            <LocalStorageProvider>
                <AuthProvider>
                    <BrowserRouter>
                        <CacheProvider value={cacheRtl}>
                            <ThemeProvider theme={defaultTheme}>
                                <SnackbarProvider
                                    maxSnack={3}
                                    TransitionComponent={Fade}
                                    anchorOrigin={{
                                        horizontal: "right",
                                        vertical: "bottom"
                                    }}
                                    classes={{
                                        containerAnchorOriginBottomRight: "notistack-bottom-guttered"
                                    }}
                                    Components={{
                                        reportComplete: ReportComplete,
                                        uploadFile: UploadFile
                                    }}
                                >
                                    <CssBaseline />
                                    <Routes />
                                </SnackbarProvider>
                            </ThemeProvider>
                        </CacheProvider>
                    </BrowserRouter>
                </AuthProvider>
            </LocalStorageProvider>
        </ErrorBoundary>
    );
}

export default Container;
