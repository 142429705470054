/* eslint-disable react-hooks/rules-of-hooks */

// import DiscountOutlined from "@mui/icons-material/DiscountOutlined";
// import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
// import { Fragment } from "react";
// import useDialogProps from "../../../utils/useDialogProps";
// import DiscountFormDialog from "../components/DiscountFormDialog";
// import Text from "../../../components/Text";
// import pricify from "../../../utils/pricify";


const People = {
    // 'ProductForm.Hooks': injectedParams => ({
    //     'discountFormDialogHook': useDialogProps()
    // }),
    // 'ProductForm.PostFormGrid': ({ getValue, discountFormDialogHook, key }) => {
    //     const [discountFormDialogProps, openDiscountFormDialog, closeDiscountFormDialog] = discountFormDialogHook;

    //     return (
    //         <Grid item xs={12} md={6} key={key}>
    //             <List disablePadding dense>
    //                 <ListItem disablePadding dense>
    //                     <ListItemButton onClick={() => openDiscountFormDialog()}>
    //                         <ListItemIcon>
    //                             <DiscountOutlined />
    //                         </ListItemIcon>
    //                         <ListItemText
    //                             primary={getValue('discount') ? <span><Text>{pricify(getValue('discount').amount)}</Text> <small>ریال</small></span> : <small>برای انتخاب کلیک کنید</small>}
    //                             secondary="تخفیف"
    //                         />
    //                     </ListItemButton>
    //                 </ListItem>
    //             </List>
    //         </Grid>
    //     )
    // },
    // 'ProductForm.initialValues': () => ({
    //     discount: null
    // }),
    // 'ProductForm.Footer': ({ getValue, setValue, discountFormDialogHook, key }) => {
    //     const [discountFormDialogProps, openDiscountFormDialog, closeDiscountFormDialog] = discountFormDialogHook;

    //     return (
    //         <Fragment key={key}>
    //             <DiscountFormDialog
    //                 {...discountFormDialogProps}
    //                 defaultValues={getValue("discount")}
    //                 onSubmit={values => setValue("discount", values)}
    //             />
    //         </Fragment>
    //     )
    // },
    //    
}

export default People;