import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useMiddleware } from "../../../../Application";
import DataList from "../../../../components/DataList";
import Indicator from "../../../../components/Indicator";

export const ClientDetailsOverview = ({ client }) => {

    const middleware = useMiddleware('ceremonies/reservation.clients');

    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState(null);

    const loadClientAsync = async () => {
        const response = await middleware.latestInvoice(client.id);
        if(response.ok) {
            setInvoice(response.data);
            setLoading(false)
        }
    }

    useEffect(() => {
        if(client) loadClientAsync();
    }, []);

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>آخرین صورتحساب</Typography>
            <DataList
                data={
                    [{
                        title: 'تاریخ ثبت',
                        description: loading ? <Indicator /> : (invoice ? invoice.createdAt : '--')
                    }, {
                        title: 'تاریخ رزرو',
                        description: loading ? <Indicator /> : (invoice ? invoice.reservedAt : '--')
                    }, {
                        title: 'مجموع فاکتور',
                        description: loading ? <Indicator /> : (invoice ? invoice.total : '--')
                    }, {
                        title: 'تاریخ برگشت',
                        description: loading ? <Indicator /> : (invoice ? invoice.returnedAt : '--')
                    }]
                }
            />
        </Grid>
    )
}