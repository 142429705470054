/* eslint-disable react-hooks/rules-of-hooks */
import { Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Fragment } from "react";
import Text from "../../../components/Text";
import useDialogProps from "../../../utils/useDialogProps";
import PricingsDialog from "../components/PricingsDialog";
import PricesFormDialog from "../components/PricesFormDialog";
import PaymentsOutlined from "@mui/icons-material/PaymentsOutlined";
import { useAuth } from "../../../auth";
import DataListBox from "../../../components/DataListBox";
import sortBy from "lodash/sortBy";
import pricify from "../../../utils/pricify";


const ProductPricesGridItem = ({ product, loading }) => {

    const { inject } = useAuth();

    const data = product && product.prices ? product.prices.map((price, index) => ({
        title: price.pricing.name,
        priority: (index + 1) * 100,
        description: <><Text>{pricify(price.amount)}</Text> <small>ریال</small></>
    })) : [];


    return (
        <DataListBox
            title="تعرفه‌ها"
            loading={loading}
            data={sortBy([
                ...data,
                ...(inject('Products', 'ProductDetails.Prices.DataList', { product, loading }) || [])
            ], o => o.priority)}
        />
    )
}


const Products = {
    'ProductsList.Hooks': injectedParams => ({
        'pricingsFormHook': useDialogProps("pricings")
    }),
    'ProductsList.ActionsList': ({ pricingsFormHook }) => {
        const [pricingsDialogProps, openPricingsDialog, closePricingsDialog] = pricingsFormHook;

        return {
            title: 'مدیریت تعرفه‌ها',
            icon: <PaymentsOutlined fontSize="inherit" />,
            onClick: () => openPricingsDialog()
        }
    },
    'ProductsList.Footer': ({ pricingsFormHook, key }) => {
        const [pricingsDialogProps, openPricingsDialog, closePricingsDialog] = pricingsFormHook;

        return (
            <Fragment key={key}>
                <PricingsDialog {...pricingsDialogProps} />
            </Fragment>
        )
    },
    'ProductForm.Hooks': injectedParams => ({
        'pricesDialogHook': useDialogProps()
    }),
    'ProductForm.PostFormGrid': ({ getValue, pricesDialogHook, key }) => {
        const [pricesDialogProps, openPricesDialog, closePricesDialog] = pricesDialogHook;

        const renderPricesLabel = () => {
            if (!getValue("prices") || getValue("prices").length < 1) {
                return (<small>برای انتخاب کلیک کنید</small>);
            }

            return getValue("prices").map((price, index) => {
                return (
                    <small style={{ display: "flex" }} key={index}>
                        <span>{price.pricing.name}</span>
                        <span style={{ marginRight: 'auto' }}><Text>{price.amount}</Text> ریال</span>
                    </small>
                )
            }).reduce((prev, curr) => [prev, <br key={new Date()} />, curr])
        }

        return (
            <Grid item xs={12} md={6} key={key}>
                <List disablePadding dense>
                    <ListItem disablePadding dense>
                        <ListItemButton onClick={() => openPricesDialog()}>
                            <ListItemIcon>
                                <PaymentsOutlined />
                            </ListItemIcon>
                            <ListItemText
                                primary={renderPricesLabel()}
                                secondary="تعرفه‌ها"
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Grid>
        )
    },
    'ProductForm.initialValues': () => ({
        prices: []
    }),
    'ProductForm.Footer': ({ getValue, setValue, pricesDialogHook, key }) => {
        const [pricesDialogProps, openPricesDialog, closePricesDialog] = pricesDialogHook;

        return (
            <Fragment key={key}>
                <PricesFormDialog
                    {...pricesDialogProps}
                    defaultValue={getValue('prices')}
                    onConfirm={value => {
                        setValue('prices', value);
                        closePricesDialog();
                    }}
                />
            </Fragment>
        )
    },
    'ProductDetails.Overview.Grid': injectedParams => (<ProductPricesGridItem {...injectedParams} />),
}

export default Products;