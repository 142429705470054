import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class AuthenticatedMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Authenticated';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/self';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/notifications',
        markAllRead: '/notifications/markAllRead'
    }

    /**
     * Get list of user notifications
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(
                this.serializePath(this.paths.list, args)
            );

            return {
                ...response.data
            }
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Mark all user notification as read
     *  
     * @return Promise
     */
    async markAllRead() {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.markAllRead,
            });

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default AuthenticatedMiddleware;