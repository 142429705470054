import Main from "./main";
import PeopleMiddleware from "./PeopleMiddleware";
import Invoices from "./invoices";
import Contracts from "./contracts";


const middlewares = [
    Main,
    PeopleMiddleware,
    Invoices,
    Contracts
];

export default middlewares;