import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class ContractsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Contracts';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/contracts';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        getNewNumber: '/new/number',
        createOrUpdate: '/',
        details: '/:contractId',
        delete: '/:contractId',
        //
    }

    /**
     * Get list of contracts match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args));
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get contract details
     * 
     * @param {*} args 
     * @return Promise 
     */
    async details(contractId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.details, { contractId })
            );
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get new number for new creating invoice
     *  
     * @return Promise
     */
    async getNewNumber() {
        try {
            const response = await axios(this.paths.getNewNumber);
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing contract
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {

            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an existing contract from db
     *  
     * @return Promise
     */
    async delete(contractId) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { contractId })
            });
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    //
}

export default ContractsMiddleware;