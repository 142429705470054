import AddInvoiceIconOutlined from "@mui/icons-material/AssignmentTurnedInOutlined";
import InvoiceListIconOutlined from "@mui/icons-material/AssignmentOutlined";
import ReportsIconOutlined from "@mui/icons-material/AnalyticsOutlined";


const tasks = {
    issue: {
        title: `فاکتور رزرو`,
        Icon: AddInvoiceIconOutlined,
        path: `/invoices/reserve/new`
    },
    history: {
        title: `تاریخچه رزرو`,
        Icon: InvoiceListIconOutlined,
        path: `/invoices/reserve`
    },
    reports: {
        title: `گزارشات رزرو`,
        Icon: ReportsIconOutlined,
        path: `/reports`
    },
    // details: (invoice) => ({
    //     title: `فاکتور ${invoice.number}`,
    //     Icon: InvoiceListIconOutlined,
    //     path: `/invoice/${invoice.id}`
    // }),
    edit: (invoice) => ({
        title: `اصلاح فاکتور ${invoice.number}`,
        Icon: InvoiceListIconOutlined,
        path: `/invoice/${invoice.id}/edit`
    })
}

export default tasks;