import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button, DialogActions,
    DialogTitle, Divider, IconButton, InputAdornment, ListItemButton, ListItemIcon, ListItemText
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMiddleware } from "../../../Application";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import ResponsiveTable from "../../../components/ResponsiveTable";
import TextField from "../../../components/TextField";
import useDebounce from '../../../utils/useDebounce';
import useDialogProps from "../../../utils/useDialogProps";
import useGetRowNumber from "../../../utils/useGetRowNumber";
import useResponsiveTableFetch from "../../../utils/useResponsiveTableFetch";
import PersonFormDialog from './PersonFormDialog';


const PeopleTable = ({
    loading,
    meta,
    clients,
    selected,
    onSelect,
    infiniteScrollProps,
    page,
    perPage,
    onPageChange,
    onPerPageChange
}) => {

    const getRowNumber = useGetRowNumber();

    const isSelected = (category) => {
        if (!selected)
            return false;

        if (Array.isArray(selected))
            return selected.filter(m => m.id === category.id).length > 0;

        return selected.id === category.id;
    }


    return (
        <ResponsiveTable
            data={clients}
            loading={loading}
            infiniteScrollProps={infiniteScrollProps}
            definitions={[
                {
                    headerCellProps: {
                        align: "center"
                    },
                    rowCellProps: {
                        align: "center",
                        width: 25
                    },
                    renderCell: (row, index) => (
                        <div>
                            {isSelected(row) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        </div>
                    ),
                },
                {
                    title: 'ردیف',
                    headerCellProps: {
                        align: "center",
                        width: 100
                    },
                    rowCellProps: {
                        align: "center"
                    },
                    renderCell: (row, index) => getRowNumber(index)
                },
                {
                    title: 'کد شخص',
                    field: 'code',
                    headerCellProps: {
                        align: "center",
                        width: 100
                    },
                    rowCellProps: {
                        align: "center"
                    },
                    // renderList: row => <strong>{row.fullName}</strong>,
                },
                {
                    field: 'fullName',
                    title: 'شخص',
                    // renderList: row => <strong>{row.fullName}</strong>,
                }
            ]}
            renderSheetTitle={row => row.name}
            renderListItemChildren={(row, index) => (
                <ListItemButton
                    key={index}
                    selected={isSelected(row)}
                    onClick={() => onSelect(row)}
                >
                    <ListItemIcon>
                        {isSelected(row) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    </ListItemIcon>
                    <ListItemText
                        primary={<strong>{row.fullName}</strong>}
                    />
                </ListItemButton>
            )}
            tableRowProps={row => ({
                hover: true,
                striped: false,
                selected: isSelected(row),
                onClick: e => onSelect(row)
            })}

            paginationProps={{
                count: meta.total,
                rowsPerPage: perPage || 25,
                page: page || 0,
                onPageChange: (e, page) => onPageChange(page),
                onRowsPerPageChange: e => onPerPageChange(e.target.value)
            }}

            listItemProps={{
                disablePadding: selected
            }}

            listProps={{
                disablePadding: !selected
            }}
        />
    )
}


const PersonSelectDialog = ({
    roles,
    open,
    onClose,
    selected,
    onSelect,
    onClear
}) => {

    const middleware = useMiddleware('People.Main');

    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 500);

    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        perPage: 25
    })

    const { data, total, loading, loadMore, reload } = useResponsiveTableFetch({
        handleFetch: params => middleware.list({
            ...params,
            ...paginationParams,
            query: debouncedQuery
        }),
        loadDeps: [paginationParams, debouncedQuery],
        infiniteScrollDefaultParams: paginationParams
    });

    const { enqueueSnackbar } = useSnackbar();

    const [formDialogProps, openFormDialog, closeFormDialog] = useDialogProps();
    const handleCreateOrUpdate = (message) => {
        enqueueSnackbar(message);
        closeFormDialog();
        reload();
    }


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>انتخاب شخص</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    size="small"
                    label="کد یا نام و نام خانوادگی شخص"
                    variant="filled"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    // autoFocus
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                    }}
                />
            </Box>
            <PeopleTable
                loading={loading}
                clients={data}
                meta={{ total }}
                selected={selected}
                onSelect={onSelect}
                infiniteScrollProps={{
                    loading: false,
                    hasNextPage: data.length < total,
                    onLoadMore: loadMore
                }}
                page={paginationParams.page}
                perPage={paginationParams.perPage}
                onPageChange={page => setPaginationParams({ ...paginationParams, page })}
                onPerPageChange={perPage => setPaginationParams({ ...paginationParams, perPage })}
            />
            <Divider />
            <DialogActions>
                <Button
                    type="button"
                    onClick={() => openFormDialog({
                        onCreateOrUpdate: handleCreateOrUpdate('مشخصات شخص ثبت گردید.')
                    })}
                    variant="contained"
                >
                    ثبت جدید
                </Button>
                {onClear && <Button type="button" onClick={onClear}>پاک کردن</Button>}
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>

            <PersonFormDialog
                {...formDialogProps}
                defaultValues={{ roles }}
                hideRulesSelect={roles.length > 0}
                middleware={middleware}
            />
        </ResponsiveDialog>
    )
}

export default PersonSelectDialog