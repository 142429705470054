import { styled, keyframes, alpha } from "@mui/material";

const Indicator = styled('div')(
    ({ theme }) => {

        const dotFlashing = keyframes`
            0% {
                background-color: ${theme.palette.primary.main};
            }
            50%,
            100% {
                background-color: ${alpha(theme.palette.primary.main, 0.3)};
            }
        `;

        return {
            position: 'relative',
            display: 'inline-block',
            width: 5,
            height: 5,
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
            color: alpha(theme.palette.primary.main, 0.7),
            animation: `${dotFlashing} .5s infinite linear alternate`,
            animationDelay: '.25s',

            "&::before, &::after": {
                content: `""`,
                display: 'inline-block',
                position: 'absolute',
                top: 0
            },
            "&::before": {
                left: -8,
                width: 5,
                height: 5,
                borderRadius: 5,
                backgroundColor: theme.palette.primary.main,
                color: alpha(theme.palette.primary.main, 0.7),
                animation: `${dotFlashing} .5s infinite alternate`,
                animationDelay: '0s',
            },
            "&::after": {
                left: 8,
                width: 5,
                height: 5,
                borderRadius: 5,
                backgroundColor: theme.palette.primary.main,
                color: alpha(theme.palette.primary.main, 0.7),
                animation: `${dotFlashing} .5s infinite alternate`,
                animationDelay: '.5s',
            }
        }
    }
)

export default Indicator;