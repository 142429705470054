import { useLocation, useNavigate } from "react-router";


const useNavigateTask = () => {

    const navigate = useNavigate();
    const location = useLocation();


    return (task, options = {}) => navigate(
        task.path,
        { state: options.replace ? location.state : { prev: location }, ...options }
    );
}

export default useNavigateTask