import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useMiddleware } from "../../../Application";
import NumberTextField from "../../../components/NumberTextField";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import useFetch from "../../../utils/useFetch";


const PricesFormDialog = ({ open, onClose, defaultValue = [], onConfirm }) => {

    const middleware = useMiddleware('Pricing.Main');

    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])


    const {
        data,
        loading,
        reload
    } = useFetch({
        initialState: { data: [], loading: true },
        handler: () => middleware.list(),
        deps: []
    });

    const { data: pricings } = data;

    const getPricingValue = (pricing) => value.filter(p => p.pricing._id === pricing._id).length > 0 ? value.filter(p => p.pricing._id === pricing._id)[0].amount : '';
    const setPricingValue = (pricing, amount) => {
        if (value.filter(p => p.pricing._id === pricing._id).length > 0) {
            const newValue = value.map(p => p.pricing._id === pricing._id ? ({ ...p, amount }) : p)
            setValue(newValue);
        } else {
            setValue([...value, { pricing, amount: '' }]);
        }
    };


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>تعیین تعرفه‌ها</Box>
                <IconButton onClick={onClose} size="small">
                    <ArrowBack />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {(!loading && pricings.length < 1) && <Typography textAlign="center" py={5} px={3}>تعرفه‌ای تعریف نشده است</Typography>}
                {!loading && pricings.map((pricing, index) => (
                    <Box key={index}>
                        <NumberTextField
                            fullWidth={true}
                            size="small"
                            label={pricing.name}
                            dir="ltr"
                            variant="filled"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>
                            }}
                            value={getPricingValue(pricing)}
                            onChange={(e) => setPricingValue(pricing, e.target.value)}
                        />
                    </Box>
                ))}
            </DialogContent>
            <Divider />
            <DialogActions>
                {onConfirm && (
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => onConfirm(value)}
                    >
                        تایید و بستن
                    </Button>
                )}
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>بستن</Button>
            </DialogActions>
        </ResponsiveDialog>
    )
}

export default PricesFormDialog