import Visibility from "@mui/icons-material/VisibilityOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import { Box, Alert, Button, Grid, IconButton, InputAdornment, styled, Typography, useTheme } from "@mui/material";
import omit from "lodash/omit";
import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import { useMiddleware } from "../../../Application";
import bg from '../../../assets/bg.jpg';
import { useAuth } from "../../../auth";
import TextField from "../../../components/TextField";
import IconLogo from "../../../components/IconLogo";


const Logo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Ebtekar',
    fontSize: 40,
    lineHeight: 1,
    color: 'inherit',
    "&>span": {
        color: theme.palette.primary.main,
    }
}));

const Captcha = styled(Box)(({ theme }) => ({
    "& canvas": {
        verticalAlign: 'middle'
    }
}));
// const OAuthButton = styled(Button)(({ theme }) => ({
//     padding: '10px 10px'
// }))


const Login = () => {

    const usernameRef = useRef();
    const passwordRef = useRef();
    const codeRef = useRef();

    const [method, setMethod] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [resendCountdown, setResendCountdown] = useState(0);

    const [error, setError] = useState('');

    const { user, login } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();

    const from = location.state?.from;

    const middleware = useMiddleware('authentication.main');

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const verifyAsync = async () => {
        const response = await middleware.verify(username);
        if (response.ok) {
            switch (response.result) {
                case 0:
                    setError(response.message);
                    setMethod(null);
                    break;
                case 1:
                    setError('');
                    setMethod("SMS");
                    setResendCountdown(120)
                    break;
                default:
                    setError('');
                    setMethod("PASSWORD");
                    break;
            }
        } else {
            setError(response.errors ? Object.values(response.errors)[0][0] : response.message);
        }
    }

    const sendCodeAsync = async () => {
        const response = await middleware.sendCode(username);
        if (response.ok) {
            setError('');
            setMethod("SMS");
            setResendCountdown(120)
        } else {
            setError(response.errors ? Object.values(response.errors)[0][0] : response.message);
        }
    }

    const loginAsync = async () => {

        const response = method === "PASSWORD" ?
            await middleware.login({ username, password }) :
            await middleware.loginUsingSMS({ username, password });
        if (response.ok) {
            login(omit(response, 'ok'))
            navigate(from || '/');
        } else {
            setError(response.errors ? Object.values(response.errors)[0][0] : response.message);
        }
        setLoading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!method) await verifyAsync();
        else {
            if (method === "PASSWORD") {
                if (validateCaptcha(captcha))
                    await loginAsync()
                else
                    setError('کد امنیتی نامعتبر است.')
            } else {
                await loginAsync()
            }
        };

    }

    useEffect(() => {
        switch (method) {
            case "SMS":
                codeRef.current && codeRef.current.select();
                break;
            case "PASSWORD":
                passwordRef.current && passwordRef.current.select();
                break;
            default:
                usernameRef.current && usernameRef.current.select();
                break;
        }
    }, [method]);


    const refreshCountdown = () => setResendCountdown(resendCountdown - 1);

    useEffect(() => {
        if (resendCountdown > 0) {
            const timerId = setInterval(refreshCountdown, 1000);
            return () => clearInterval(timerId);
        }
    }, [resendCountdown])

    useEffect(() => {
        if (method === "PASSWORD")
            loadCaptchaEnginge(5, '#fff', '#333', 'numbers');
    }, [method]);

    const theme = useTheme();


    if (user) {
        return (
            <Navigate to={from || '/'} />
        )
    }

    return (
        <Grid
            container
            direction="row-reverse"
            sx={{ height: '100%' }}
        >
            <Grid
                item
                xs={12}
                sm={7}
                md={8}
                xl={9}
                container
                sx={{ height: '100%', display: { xs: 'none', md: 'flex' } }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        p: 5,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${bg})`,
                        backgroundPosition: 'center'
                    }}
                >
                    {/* This is any content that we want */}
                </Box>
            </Grid>
            <Grid item xs={12} sm={5} md={4} xl={3}>
                <Box
                    sx={theme => ({
                        height: '100%',
                        background: theme.palette.common.white,
                        p: { xs: 7, md: 5 }
                    })}
                >
                    <Box
                        sx={{
                            mt: 4,
                            mb: 10,
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Box
                            component={IconLogo}
                            sx={{
                                fontSize: 60,
                                color: 'primary.main',
                                mr: 1
                            }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <Logo>فرا<span>هاب</span></Logo>
                            <Typography sx={{ fontFamily: 'Kalameh', fontWeight: 900, fontSize: '1rem' }}>یکپارچه، نوآورانه و هوشمند</Typography>
                        </Box>
                    </Box>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            mb: 5
                        }}
                    >ورود به حساب کاربری</Typography>
                    {/* <Stack spacing={1} direction="row">
                        <OAuthButton
                            size="large"
                            variant="outlined"
                            color="inherit"
                            fullWidth
                        >
                            ورود با گوگل
                        </OAuthButton>
                        <OAuthButton
                            size="large"
                            variant="outlined"
                            color="inherit"
                            fullWidth
                        >
                            ورود با گیت‌هاب
                        </OAuthButton>
                    </Stack> */}
                    {/* <Divider sx={{ my: 3 }}>یا</Divider> */}
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    <form noValidate onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex' }}>
                            <TextField
                                fullWidth
                                dir="ltr"
                                InputLabelProps={{
                                    dir: 'rtl'
                                }}
                                inputRef={usernameRef}
                                variant="filled"
                                label="شماره همراه"
                                type="number"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                disabled={method !== null}
                                placeholder="09XXXXXXXXX"
                                InputProps={{
                                    inputProps: {
                                        className: "pl-fw-secondary"
                                    },
                                    startAdornment: method !== null && (
                                        <InputAdornment position="start">
                                            <Button
                                                onClick={() => setMethod(null)}
                                                edge="start"
                                            >
                                                تغییر شماره
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 2, display: method === "PASSWORD" ? 'block' : 'none' }}>
                            <TextField
                                fullWidth
                                dir="ltr"
                                InputLabelProps={{
                                    dir: 'rtl'
                                }}
                                variant="filled"
                                label="رمز عبور"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                                sx={{ mb: 2 }}
                                inputRef={passwordRef}
                                InputProps={{
                                    startAdornment: theme.direction === "rtl" && (
                                        <InputAdornment position="start">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="start"
                                                tabIndex={-1}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: theme.direction === "ltr" && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                tabIndex={-1}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                fullWidth
                                dir="ltr"
                                InputLabelProps={{
                                    dir: 'rtl'
                                }}
                                inputRef={codeRef}
                                variant="filled"
                                label="کد امنیتی"
                                value={captcha}
                                onChange={e => setCaptcha(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <Captcha>
                                                <LoadCanvasTemplateNoReload />
                                            </Captcha>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button sx={{ mt: 1 }} onClick={() => sendCodeAsync()}>ورود با رمز یکبار مصرف</Button>
                        </Box>
                        <Box sx={{ mt: 2, display: method === "SMS" ? 'block' : 'none' }}>
                            <p>کد تاییدی که از طریق پیامک برای شما ارسال گردید را وارد کنید.</p>
                            <TextField
                                fullWidth
                                dir="ltr"
                                InputLabelProps={{
                                    dir: 'rtl'
                                }}
                                inputRef={codeRef}
                                variant="filled"
                                type="number"
                                label="کد تایید"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                InputProps={{
                                    startAdornment: method !== null && (
                                        <InputAdornment position="start">
                                            <Button
                                                onClick={() => verifyAsync()}
                                                edge="start"
                                                disabled={resendCountdown > 0}
                                            >
                                                {
                                                    resendCountdown > 0 ?
                                                        new Date(resendCountdown * 1000).toISOString().slice(14, 19)
                                                        : "ارسال مجدد"
                                                }
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 2, display: 'flex' }}>
                            <Button
                                variant="contained"
                                type="submit"
                                size="large"
                                sx={{ mx: 'auto', p: '10px 25px' }}
                            >{method ? "ورود به حساب" : "مرحله بعد"}</Button>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Login;