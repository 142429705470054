import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMiddleware } from "../../../Application";


const ContractSettleConfirmation = ({ open, onClose, onSettle, contract }) => {

    const middleware = useMiddleware('transactions.contracts');

    const { enqueueSnackbar } = useSnackbar();

    const settleAsync = async () => {
        try {
            const response = await middleware.settle(contract.id);
            if (response.ok) {
                enqueueSnackbar(`صورت‌حساب تسویه شد.`);
                onClose();
                onSettle(response.contract);
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                تایید تسویه کامل قرارداد
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    تایید تسویه حساب کامل قرارداد شماره {contract?.number} بنام {(contract?.client || {}).fullName}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={settleAsync} variant="contained" color="primary" autoFocus>تسویه کامل</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={onClose}>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ContractSettleConfirmation