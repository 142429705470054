import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class SubscriptionsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Subscriptions';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/subscriptions';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        subscribe: '/subscribe',
    }

    /**
     * Get a list of workspace subscriptions
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args));

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Subscribe to specific module
     * 
     * @param {*} args 
     * @return Promise 
     */
    async subscribe(data) {
        try {
            const response = await axios({
                method: 'POST',
                url: this.paths.subscribe,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    // /**
    //  * Generate the path to subscribe to module
    //  * 
    //  * @param {string} moduleId
    //  * @return string 
    //  */
    // getSubscribePath(moduleId, params) {
    //     return this.serializePath(this.replaceParams(this.paths.subscribe, { moduleId }), params);
    // }
}

export default SubscriptionsMiddleware;