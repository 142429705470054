import { TableCell, Link as MuiLink } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"

export const InvoiceItemsTableHeadCellsAfterTitle = () => {
    return (
        <TableCell>امانت‌دهنده</TableCell>
    )
}

export const InvoiceItemsTableBodyCellsAfterTitle = ({ item }) => {

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <TableCell>
            {item.bailer ? (
                <MuiLink
                    color="inherit"
                    onClick={() => {
                        navigate(
                            '/client/'.concat(item.bailer.id),
                            { state: { prev: location } }
                        )
                    }}
                >
                    {item.bailer.fullName}
                </MuiLink>
            ) : '-'}
        </TableCell>
    )
}