import AddInvoiceIconOutlined from "@mui/icons-material/AssignmentTurnedInOutlined";
import InvoiceListIconOutlined from "@mui/icons-material/AssignmentOutlined";
// import ReportsIconOutlined from "@mui/icons-material/AnalyticsOutlined";


const tasks = {
    samples: {
        title: `نمونه قراردادها`,
        Icon: AddInvoiceIconOutlined,
        path: `/contract-samples`
    },
    manageContracts: {
        title: `قراردادها`,
        Icon: InvoiceListIconOutlined,
        path: `/contracts`
    },
}

export default tasks;