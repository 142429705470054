import { lazy } from 'react';
import Loadable from '../../../components/Loadable';

const Cases = Loadable(lazy(() => import('./pages/Cases/Main')));
const CaseDetails = Loadable(lazy(() => import('./pages/CaseDetails/CaseDetails')));
const CaseOverview = lazy(() => import('./pages/CaseDetails/CaseOverview'));


const routes = (module) => {
	return [
		{
			variant: "dashboard",
			private: true,
			path: '/cases',
			element: <Cases />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole('owner') || hasPermission('cases:view')
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/case/:caseId',
			element: <CaseDetails />,
			children: [
				{ variant: "dashboard", private: true, index: true, element: <CaseOverview /> },
				...(module.inject('routes.cases.details') || [])
			],
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole('owner') || hasPermission('cases:view')
			}
		}
	]
};

export default routes;