import replace from "lodash/replace";
import forOwn from "lodash/forOwn";


class Middleware {

    /**
     * The middleware application
     * 
     * @var Application
     */
    app;

    /**
     * The middleware module
     * 
     * @var Module
     */
    module;

    /**
     * The middleware name
     * 
     * @var string
     */
    name;

    /**
     * The middleware base path
     * 
     * @var string
     */
    basePath;

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths;

    /**
     * Create the middleware instance
     *
     * @return void
     */
    constructor(module) {
        this.module = module;
        this.app = module.app;
    }

    /**
     * Register the middleware routes
     *
     * @return void
     */
    registerPaths() {
        let self = this;
        forOwn(this.paths, (value, key) => {
            self.paths[key] = [
                self.app.host,
                this.removeSlashesFromPath(self.module.basePath),
                this.removeSlashesFromPath(self.basePath),
                this.removeSlashesFromPath(value)
            ].filter(Boolean).join('/');
        });
    }

    /**
     * remove slashes from path string
     * 
     * @param {string} path path string
     * @returns string
     */
    removeSlashesFromPath(path) {
        if (!Boolean(path)) return path;

        let modified = path;

        if (modified.charAt(0) === '/') {
            modified = modified.slice(1)
        }
        if (modified.charAt(path.length - 1) === '/') {
            modified = modified.slice(0, -1)
        }

        return modified;
    }

    /**
     * replace path parameters
     * 
     * @param {string} path path string
     * @param Object params
     * @returns string
     */
    replaceParams(path, params = {}) {
        let modified = path;
        forOwn(params, (value, key) => {
            if (!path.includes(`:${key}`)) {
                throw new Error(`The param ${key} not exist in path ${path}`)
            }

            modified = replace(modified, `:${key}`, value || '')
        })

        return modified;
    }

    /**
     * Convert an object to query string
     * 
     * @param Object object to serialize
     * @returns serialized query
     */
    serializeQuery(obj) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    /**
     * Get serialized path
     * 
     * @param string path
     * @param Object object to serialize
     * @returns serialized query
     */
    serializePath(path, args) {
        return [
            this.removeSlashesFromPath(path),
            this.serializeQuery(args)
        ].filter(Boolean).join('?')

    }
}

export default Middleware;