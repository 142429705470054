import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMiddleware } from "../../../Application";
import useResponsiveTableFetch from "../../../utils/useResponsiveTableFetch";
import FilesTable from "./FilesTable";


const StorageView = ({ reference, extractReferenceId }) => {

    const middleware = useMiddleware('Storage.Main');

    const params = useParams();
    const [searchParams] = useSearchParams();

    const { data, total, loading, loadMore, reload } = useResponsiveTableFetch({
        handleFetch: extraParams => middleware.list({
            ...((reference && extractReferenceId) && {
                reference,
                referenceId: extractReferenceId(params),
            }),
            ...extraParams,
            ...Object.fromEntries([...searchParams])
        }),
        loadDeps: [searchParams],
        infiniteScrollDefaultParams: {
            page: searchParams.has('page') ? searchParams.get('page') : 0,
            perPage: searchParams.has('perPage') ? searchParams.get('perPage') : 25,
        }
    })

    const [uploadDialogProps, setUploadDialogProps] = useState({ open: false });


    return (
        <div>
            <FilesTable
                files={data}
                loading={loading}
                meta={{ total }}
                infiniteScrollProps={{
                    loading: false,
                    hasNextPage: data.length < total,
                    onLoadMore: loadMore
                }}
            />
        </div>
    )
}

export default StorageView;