import CloseIcon from "@mui/icons-material/Close";
import CloudDoneOutlined from "@mui/icons-material/CloudDoneOutlined";
import Cloud from "@mui/icons-material/CloudOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, CircularProgress, Divider, styled } from "@mui/material";
import MuiCard from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MuiTypography from "@mui/material/Typography";
import { SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { useComponent } from "../../Application";

const Root = styled(SnackbarContent)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        minWidth: "344px !important",
        maxWidth: "400px"
    }
}));

const Card = styled(MuiCard)(({ theme }) => ({
    width: "100%",
    // backgroundColor: theme.palette.primary.main
}));

const Typography = styled(MuiTypography)(({ theme }) => ({
    fontWeight: 500
    // color: theme.palette.common.white
}));

const ActionRoot = styled(CardActions)(({ theme }) => ({
    padding: theme.spacing(1, 1, 1, 2),
    justifyContent: "space-between"
}));

const Icons = styled('div')(({ theme }) => ({
    marginLeft: "auto"
}));

const ExpandableIconButton = styled(IconButton, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    padding: theme.spacing(1, 1),
    transform: "rotate(0deg)",
    // color: "#000",
    transition: "all .2s",

    ...(open && {
        transform: "rotate(180deg)"
    })
}));


const ReportComplete = forwardRef(
    ({ id, ...props }, ref) => {

        const { closeSnackbar } = useSnackbar();
        const [expanded, setExpanded] = useState(true);

        const handleExpandClick = useCallback(() => {
            setExpanded((oldExpanded) => !oldExpanded);
        }, []);

        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);

        const UploadListItem = useComponent('Storage.UploadListItem');

        const [files, setFiles] = useState([]);
        const allFilesUploaded = () => files.every(file => file.uploaded);

        useEffect(() => {
            if (props.files)
                setFiles(props.files.map(file => ({ ...file, uploaded: false })))
        }, [props.files]);

        useEffect(() => {
            if (files.length > 0 && allFilesUploaded()) {
                props.onComplete && props.onComplete();
            }
        }, [files])


        return (
            <Root ref={ref}>
                <Card>
                    <ActionRoot>
                        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                            {allFilesUploaded() ? (
                                <CloudDoneOutlined fontSize="small" sx={{ mr: 1 }} />
                            ) : (
                                <Box sx={{ mr: 2, position: 'relative' }}>
                                    <Cloud fontSize="small" />
                                    <CircularProgress
                                        size={28}
                                        sx={theme => ({
                                            color: theme.palette.text.primary,
                                            position: 'absolute',
                                            top: -3,
                                            left: -4,
                                            zIndex: 1,
                                        })}
                                    />
                                </Box>
                            )}
                            <Typography variant="body2">
                                {allFilesUploaded() ? 'همه فایل‌ها بارگذاری شده‌اند.' : 'در حال بارگذاری'}
                            </Typography>
                        </Box>
                        <Icons>
                            <ExpandableIconButton
                                aria-label="Show more"
                                size="small"
                                open={expanded}
                                onClick={handleExpandClick}
                            >
                                <ExpandMoreIcon />
                            </ExpandableIconButton>
                            <ExpandableIconButton
                                size="small"
                                onClick={handleDismiss}
                            >
                                <CloseIcon fontSize="small" />
                            </ExpandableIconButton>
                        </Icons>
                    </ActionRoot>
                    <Collapse in={expanded} timeout="auto">
                        <Box>
                            <Divider />
                            {files.map((file, index) => (
                                <UploadListItem
                                    key={index}
                                    {...file}
                                    onUploadComplete={() => {
                                        setFiles(files.map((f, idx) => idx === index ? ({ ...f, uploaded: true }) : f))
                                    }}
                                />
                            ))}
                        </Box>
                    </Collapse>
                </Card>
            </Root>
        );
    }
);

// ReportComplete.displayName = "ReportComplete";

export default ReportComplete;
