import { SwipeableDrawer, Drawer } from "@mui/material";


const BottomSheet = ({ swipeable = false, ...props }) => {

    const Component = swipeable ? SwipeableDrawer : Drawer;

    return <Component
        anchor="bottom"
        sx={theme => ({
            zIndex: 1202,
            "&>.MuiPaper-root": {
                paddingTop: theme.spacing(1),
                borderTopLeftRadius: theme.spacing(1),
                borderTopRightRadius: theme.spacing(1),
                "&:before": {
                    content: `""`,
                    width: '40px',
                    height: '6px',
                    backgroundColor: 'rgb(224, 224, 224)',
                    borderRadius: '3px',
                    position: 'absolute',
                    top: '8px',
                    right: 'calc(50% - 20px)'
                }
            },
        })}

        {...(swipeable && {
            onOpen: () => { }
        })}

        {...props}
    />
}

export default BottomSheet;