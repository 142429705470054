import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogContent, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMiddleware } from "../../../Application";
import Picture from "../../../assets/change-password.jpg";
import TextField from "../../../components/TextField";
import useForm from "../../../utils/useForm";


const ChangePasswordDialog = ({ open, onClose, onPasswordChange, dialogProps = {} }) => {

    const middleware = useMiddleware('Authentication.Authenticated');

    const initialValues = {
        password: '',
        password_confirmation: ''
    }

    const {
        register,
        loading,
        handleSubmit,
    } = useForm({
        initialValues,
        handlePost: data => middleware.changePassword(data),
        onSubmit: onPasswordChange
    });

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            fullScreen={isMobile}
            scroll="paper"
            BackdropProps={{
                style: {
                    backdropFilter: 'blur(3px)'
                }
            }}
            {...dialogProps}
        >
            <DialogContent>
                <Box>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ height: '100%', display: 'flex' }}>
                                <Box
                                    component='img'
                                    src={Picture}
                                    sx={{ width: '100%', height: 'auto', display: 'block', m: 'auto' }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ px: 7, mb: 10 }}>
                                <Typography variant="h5" sx={{ fontWeight: 700, mt: 5, mb: 3 }}>تعیین رمز عبور</Typography>
                                <Typography variant="subtitle1" sx={{ mb: 5 }}>با تعیین رمز عبور، امنیت حساب کاربری خودتان را بالا ببرید.</Typography>
                                <form
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <Box sx={{ mb: 2 }}>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            label="رمز عبور"
                                            autoFocus
                                            type="password"
                                            InputProps={{
                                                style: {
                                                    direction: 'ltr'
                                                }
                                            }}
                                            {...register("password")}
                                        />
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            label="تکرار رمز عبور"
                                            type="password"
                                            InputProps={{
                                                style: {
                                                    direction: 'ltr'
                                                }
                                            }}
                                            {...register("password_confirmation")}
                                        />
                                    </Box>
                                    <LoadingButton
                                        variant="contained"
                                        type="submit"
                                        size="large"
                                        sx={{ px: 5 }}
                                        loading={loading}
                                    >ثبت</LoadingButton>
                                </form>
                            </Box>
                            {/* <Stack spacing={1} direction="row" justifyContent="flex-end">
                                <LoadingButton
                                    variant="text"
                                    type="submit"
                                    size="large"
                                    color="inherit"
                                >باشه برای بعدا</LoadingButton>
                                <LoadingButton
                                    variant="text"
                                    type="submit"
                                    size="large"
                                    color="error"
                                >دیگه نشون نده</LoadingButton>
                            </Stack> */}
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default ChangePasswordDialog;