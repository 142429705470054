import MainMiddleware from "./MainMiddleware";
import VisitMiddleware from "./VisitMiddleware";
import WelcomeMiddleware from "./WelcomeMiddleware";
import CampaignsMiddleware from "./CampaignsMiddleware";
import BirthdayMiddleware from "./BirthdayMiddleware";

const middlewares = [
    MainMiddleware,
    VisitMiddleware,
    WelcomeMiddleware,
    CampaignsMiddleware,
    BirthdayMiddleware,
];

export default middlewares;