import ReturnIcon from "@mui/icons-material/AssignmentReturnedOutlined";
import DeliverIcon from "@mui/icons-material/FactCheckOutlined";
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import DataList from "../../../../components/DataList";
import DetailedDate from "../../../../components/DetailedDate";


export const InvoiceReserveOverview = ({ invoice, state, setState, refetch }) => {
    return (
        <Grid item xs={12} sm={6} md={12}>
            <Card variant="outlined" className="invoice-reserve">
                <CardHeader title="اطلاعات رزرو" titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                    <DataList
                        data={
                            [{
                                title: 'تاریخ رزرو',
                                description: <DetailedDate date={invoice.reservedAt} />
                            }, {
                                title: 'مدت کرایه',
                                description: `${invoice.duration} روز`
                            }, {
                                title: 'ضمانت‌نامه',
                                description: invoice.warranty ? invoice.warranty.name : "-"
                            }, {
                                title: 'تاریخ تحویل',
                                description: invoice.isDelivered ? <DetailedDate date={invoice.deliveredAt} /> : '-'
                            }, {
                                title: 'تاریخ برگشت',
                                description: invoice.isReturned ? <DetailedDate date={invoice.returnedAt} /> : '-'
                            }]
                        }
                    />
                </CardContent>
                <CardActions>
                    {!invoice.isDelivered && (
                        <Button
                            variant="outlined"
                            startIcon={<DeliverIcon />}
                            onClick={() => setState({ ...state, deliverDialogOpen: true })}
                            size="small"
                        >تحویل</Button>
                    )}
                    {!invoice.isReturned && (
                        <Button
                            variant="outlined"
                            startIcon={<ReturnIcon />}
                            onClick={() => setState({ ...state, returnDialogOpen: true })}
                            size="small"
                        >برگشت</Button>
                    )}
                </CardActions>
            </Card>
        </Grid>
    )
}