import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import { useEffect, useState } from "react";
import DataList from "../../../../components/DataList";
import pricify from "../../../../utils/pricify";
import useDialog from "../../../../utils/useDialog";
import AddressFormDialog from "./AddressFormDialog";
import ShipmentFormDialog from "./ShipmentFormDialog";

const displayAddress = address => {
    if (!address)
        return '';

    return [
        address.province,
        address.city,
        address.area,
        address.street,
        address.address
    ].filter(Boolean).join(', ');
}

const InvoiceShipping = ({
    value,
    onChange
}) => {

    const [shippingAddress, setShippingAddress] = useState(value.shippingAddress || '');
    const [shipments, setShipments] = useState(value.shipments || []);

    const addShipment = (shipment) => {

        setShipments([...shipments, shipment]);
        // if (shipments.filter(s => s.driver.id === shipment.driver.id).length > 0) {
        //     setShipments(
        //         shipments.map(s => s.driver.id === shipment.driver.id ? ({
        //             ...s,
        //             price: Number(s.price) + Number(s.price)
        //         }) : s)
        //     )
        // } else {
        //     setShipments([...shipments, shipment]);
        // }
    }

    const removeShipment = (_shipment, index) => {
        setShipments(shipments.filter((s, i) => i !== index));
    }

    useEffect(() => {
        onChange && onChange({ shippingAddress, shipments })
    }, [shippingAddress, shipments])

    const {
        isOpen: isAddressDialogOpen,
        isOpening: isAddressDialogOpening,
        open: openAddressDialog,
        close: closeAddressDialog,
        data: addressDialogAddress
    } = useDialog();

    const {
        isOpen: isFactorDialogOpen,
        isOpening: isFactorDialogOpening,
        open: openFactorDialog,
        close: closeFactorDialog,
        data: shipmentDialogDriver
    } = useDialog();

    return (
        <Box sx={{ pb: { md: 2 } }}>
            <Card variant="outlined">
                <CardHeader title="ارسال و باربری" titleTypographyProps={{ variant: "subtitle2" }} />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ mb: 0 }}>
                        <DataList
                            data={[{
                                title: 'آدرس محل ارسال',
                                description: shippingAddress ? displayAddress(shippingAddress) : '-'
                            }]}
                        />
                    </Box>
                </CardContent>
                {shipments && shipments.length > 0 && (
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow align="center">
                                    <TableCell></TableCell>
                                    <TableCell>راننده</TableCell>
                                    <TableCell align="center">هزینه باربری</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shipments.map((shipment, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell>{shipment.driver?.fullName}</TableCell>
                                        <TableCell align="center">{pricify(shipment.price)}</TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="ویرایش عامل">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => openFactorDialog(shipment)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="حذف عامل">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => removeShipment(shipment, index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <CardActions>
                    <Button
                        size="small"
                        variant="outlined"
                        disabled={isAddressDialogOpening}
                        onClick={() => openAddressDialog(shippingAddress)}
                    >{(shippingAddress ? 'ویرایش' : 'افزودن').concat(" آدرس ارسال")}</Button>
                    <Button
                        size="small"
                        variant="contained"
                        disabled={isFactorDialogOpen}
                        onClick={() => openFactorDialog()}
                    >افزودن سرویس</Button>
                </CardActions>
            </Card>

            <AddressFormDialog
                open={isAddressDialogOpen}
                onClose={closeAddressDialog}
                address={addressDialogAddress}
                onSubmit={address => setShippingAddress(address)}
            />

            <ShipmentFormDialog
                open={isFactorDialogOpen}
                onClose={closeFactorDialog}
                onSubmit={shipment => addShipment(shipment)}
                initialValue={shipmentDialogDriver}
            />
        </Box>
    )
}

export default InvoiceShipping