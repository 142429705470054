import { useEffect, useRef } from "react";


const useAfterFirstRender = (effect, deps) => {

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        effect()
    }, deps);
}

export default useAfterFirstRender