import Module from '../../foundation/Module';
import routes from './routes';
import components from './components';
import middlewares from './middlewares';
import tasks from './tasks';
import hooks from './hooks';


class CategoriesModule extends Module {

    /**
     * The module name
     * 
     * @var string
     */
    name = 'Categories';

    /**
     * The module version
     * 
     * @var string
     */
    version = '1.0.0';

    /**
     * The module base path
     * 
     * use for routing 
     * 
     * @var string
     */
    basePath = '';

    /**
     * The module components
     * 
     * @var array
     */
    components = components;

    /**
     * The module hooks
     * 
     * @var Object
     */
    hooks = hooks;

    /**
     * Register The module
     * 
     * @return void
     */
    register() {
        this.registerMiddlewares(middlewares);
        this.registerRoutes(routes);
        this.registerTasks(tasks);
    }
}

export default CategoriesModule;