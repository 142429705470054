import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useMiddleware } from "../../../Application";
import { useSnackbar } from "notistack";
import pricify from "../../../utils/pricify";


const TransactionSettleConfirmation = ({ open, onClose, onSettle, transaction }) => {

    const middleware = useMiddleware('transactions.main');

    const { enqueueSnackbar } = useSnackbar();

    const _settle = async () => {
        try {
            const response = await middleware.markPaid(transaction.id);
            if (response.ok) {
                enqueueSnackbar(`تراکنش تسویه شد.`);
                onClose();
                onSettle(response.transaction);
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="alert-dialog-title">
                تسویه تراکنش
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    تایید تسویه تراکنش به مبلغ {pricify(transaction?.amount)} <small>ریال</small>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={_settle} variant="contained" color="primary" autoFocus>تسویه</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={onClose}>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TransactionSettleConfirmation