import CloseIcon from "@mui/icons-material/CloseOutlined";
import {
    Box,
    Button,
    DialogActions,
    DialogTitle,
    Divider,
    Grid,
    IconButton
} from "@mui/material";
import { useEffect, useState } from "react";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import TextField from "../../../components/TextField";


const AddressFormDialog = ({
    open,
    onClose,
    defaultValues = {},
    onChange = () => { }
}) => {

    const initialValues = {
        city: '',
        area: '',
        street: '',
        address: '',
        ...defaultValues
    }

    const [formData, setFormData] = useState(initialValues);
    const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    useEffect(() => {
        if (open)
            setFormData(initialValues);
    }, [defaultValues]);


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>ثبت آدرس</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <Box sx={{ pb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                label="شهر"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            // autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                label="منطقه"
                                name="area"
                                value={formData.area}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                label="خیابان"
                                name="street"
                                value={formData.street}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                fullWidth={true}
                                size="small"
                                multiline
                                rows={5}
                                label="آدرس"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Divider />
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={e => onChange(formData)}
                >
                    ثبت
                </Button>
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>
        </ResponsiveDialog>
    )
}

export default AddressFormDialog