import { lazy } from "react";
import Loadable from "../../components/Loadable";


const SubmitVisit = Loadable(lazy(() => import('./pages/SubmitVisit/SubmitVisit')));
const Welcome = Loadable(lazy(() => import('./pages/Welcome/Welcome')));
const Birthday = Loadable(lazy(() => import('./pages/Birthday/Birthday')));
const Campaigns = Loadable(lazy(() => import('./pages/Campaigns/Main')));
const AddCampaign = Loadable(lazy(() => import('./pages/AddCampaign/AddCampaign')));


const routes = module => {
	return [
		{
			variant: "dashboard",
			path: '/visit',
			element: <SubmitVisit />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			path: '/welcome',
			element: <Welcome />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			path: '/birthday',
			element: <Birthday />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/campaigns',
			element: <Campaigns />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/campaigns/add',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/loyalty-programs',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/review',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/reward',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/direct',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/wheel',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/winner',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/retarget',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/notification',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/invoice',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/form-builder',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/reminder',
			element: <AddCampaign />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
	]
};

export default routes;