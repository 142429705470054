import { Box } from '@mui/material';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LazyImage = (props) => (
    <Box
        component={LazyLoadImage}
        {...props}
    />
);

export default LazyImage;