import TourContext from "./TourContext";
import { Steps } from "intro.js-react";
import { useEffect, useState } from "react";

import 'intro.js/introjs.css';


const TourProvider = ({ children }) => {

    const [state, setState] = useState({
        key: '',
        steps: [],
        options: {}
    });
    const [tourOpen, setTourOpen] = useState();

    const openTour = () => setTourOpen(true);
    const closeTour = () => setTourOpen(false);

    const setTour = (data) => setState(data);

    const getLocalTours = () => localStorage.getItem('TOURS-COMPLETED') ?
        JSON.parse(localStorage.getItem('TOURS-COMPLETED')) :
        [];

    const completeTour = () => {
        let tours = getLocalTours();
        if (state.key !== "" && !tours.includes(state.key)) tours = [...tours, state.key];
        localStorage.setItem('TOURS-COMPLETED', JSON.stringify(tours));
        closeTour();
    }

    useEffect(() => {
        if (state.key && state.steps) {
            const tours = getLocalTours();
            if (!tours.includes(state.key)) {
                openTour();
            }
        }
    }, [state]);


    return (
        <TourContext.Provider value={{ ...state, isOpen: tourOpen, openTour, closeTour, setTour }}>
            {children}

            <Steps
                enabled={tourOpen}
                steps={state.steps || []}
                initialStep={0}
                onExit={completeTour}
                onComplete={completeTour}
                options={{
                    nextLabel: 'بعدی',
                    prevLabel: 'قبلی',
                    doneLabel: 'تمام',
                    exitOnEsc: false,
                    exitOnOverlayClick: false,
                    disableInteraction: true,
                }}
            />
        </TourContext.Provider>
    )
}

export default TourProvider;