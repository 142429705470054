import { createTheme } from "@mui/material";
import { common, grey } from '@mui/material/colors';
import { faIR } from '@mui/material/locale';
import i18n from "../i18n";

const bgColor = 'rgb(252, 252, 254)';

const theme = createTheme(
    {
        direction: i18n.dir(),
        typography: {
            // fontSize: 13,
            // fontFamily: "IRANSans, Lato, Arial, sans-serif",
            fontFamily: "IRANSansX, Lato, Arial, sans-serif",
            h6: {
                fontWeight: 600
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    "html": {
                        height: '100%'
                    },
                    "body": {
                        height: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        // backgroundImage: background,
                        backgroundAttachment: 'fixed'
                    },
                    '@supports (font-variation-settings: normal)': {
                        "body": {
                            fontFamily: "IRANSansXV, Lato, Arial, sans-serif",
                        }
                    },
                    "#root": {
                        height: '100%'
                    },
                    "*::-webkit-scrollbar": {
                        width: "10px"
                    },
                    "*::-webkit-scrollbar-track": {
                        background: "#E4EFEF"
                    },
                    "*::-webkit-scrollbar-thumb": {
                        background: grey[600],
                        borderRadius: "2px"
                    },
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                        WebkitAppearance: 'none',
                        margin: 0
                    },
                    "input[type=number]": {
                        MozAppearance: 'textfield'
                    },
                    ".notistack-bottom-guttered": {
                        left: "16px",
                        bottom: "64px"
                    },
                    ".pl-fw-secondary::placeholder": {
                        fontFamily: "'Lato', sans-serif"
                    },

                    // intro.js
                    ".introjs-skipbutton": {
                        left: 'auto',
                        right: 0
                    },
                    ".introjs-tooltipReferenceLayer *": {
                        fontFamily: "IRANSans, Lato, Arial, Arial,sans-serif"
                    },
                    ".introjs-tooltip": {
                        minWidth: 300,
                        maxWidth: 400
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        cursor: "pointer",
                        textDecoration: "none",
                        position: "relative",
                        "&:after": {
                            content: `""`,
                            position: "absolute",
                            left: 0,
                            bottom: -1,
                            width: '100%',
                            height: 1,
                            background: '#33475b',
                            opacity: 0.25,
                            transition: 'all 0.33s'
                        },
                        "&:hover:after": {
                            opacity: 1
                        }
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    '& .MuiTooltip-arrow': {
                        color: '#000'
                    },
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: '#000'
                    }
                }
            },
            // MuiInputLabel: {
            //     styleOverrides: {
            //         root: {
            //             direction: 'rtl'
            //         }
            //     },
            // },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        direction: 'ltr'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none'
                        }
                    },
                },
                variants: [
                    {
                        props: { variant: 'transparent' },
                        style: {
                            border: '1px solid transparent',
                            backgroundColor: 'rgba(255,255,255,0.3)',
                            "&:hover": {
                                backgroundColor: 'rgba(255,255,255,0.5)',
                            }
                        }
                    }
                ]
            },
            MuiIconButton: {
                variants: [
                    {
                        props: { variant: 'transparent' },
                        style: {
                            // border: '1px solid transparent',
                            backgroundColor: 'rgba(255,255,255,0.3)',
                            "&:hover": {
                                backgroundColor: 'rgba(255,255,255,0.5)',
                            }
                        }
                    }
                ]
            },
            MuiChip: {
                variants: [
                    {
                        props: { variant: 'transparent' },
                        style: {
                            // border: '1px solid transparent',
                            backgroundColor: 'rgba(255,255,255,0.3)',
                            "&:hover": {
                                backgroundColor: 'rgba(255,255,255,0.5)',
                            }
                        }
                    }
                ]
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: common.white,
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: '8px 16px',
                        fontSize: '1rem'
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        border: '1px solid rgba(0, 0, 0, 0.12)'
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        "& .MuiFilledInput-root.Mui-focused": {
                            border: "none"
                        },

                        "& .MuiFilledInput-root": {
                            backgroundColor: "inherit !important"
                        }
                    }
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before": {
                            border: "none"
                        },
                        "&:after": {
                            border: "none"
                        },
                        "&:hover:not(.Mui-disabled):before": {
                            border: "none"
                        }
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: 16
                    }
                }
            },
            // MuiCard: {
            //     styleOverrides: {
            //         root: {
            //             // backgroundColor: "rgba(255,255,255,0.8)",
            //             // "&:before": {
            //             //     content: `""`,
            //             //     position: 'absolute',
            //             //     top: 0,
            //             //     left: 0,
            //             //     width: '100%',
            //             //     height: '100%',
            //             //     backdropFilter: 'blur(10px)',
            //             // },
            //             backgroundColor: "rgba(255,255,255,0.95)",
            //             border: 'none',
            //             borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            //             position: "relative",
            //             "&:before": {
            //                 content: `""`,
            //                 position: 'absolute',
            //                 top: 0,
            //                 left: 0,
            //                 width: '100%',
            //                 height: '100%',
            //                 backdropFilter: 'blur(10px)',
            //             },
            //             ">*": {
            //                 position: "relative"
            //             }
            //         }
            //     }
            // },
            // MuiPaper: {
            //     styleOverrides: {
            //         root: {

            //             backgroundColor: "rgba(255,255,255,0.8)",
            //             border: 'none',
            //             borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            //             position: "relative",
            //             "&:before": {
            //                 content: `""`,
            //                 position: 'absolute',
            //                 top: 0,
            //                 left: 0,
            //                 width: '100%',
            //                 height: '100%',
            //                 backdropFilter: 'blur(10px)',
            //             },
            //             ">*": {
            //                 position: "relative"
            //             }
            //         }
            //     }
            // },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "inherit"
                    }
                }
            },
            // MuiTextField: {
            //     styleOverrides: {
            //         root: {
            //             "& .MuiFilledInput-root": {
            //                 backgroundColor: "inherit"
            //             }
            //         }
            //     }
            // },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        borderRadius: "4px"
                    }
                }
            }
        },
        palette: {
            // action: {
            //     hover: '#e5f5f8'
            // },
            text: {
                primary: '#33475b',
            },
            primary: {
                // main: cyan[700]
                // main: deepOrange[500],
                main: '#0067A5',
                // main: '#1C2574'
            },
            gradients: {
                success: 'linear-gradient(to left, #11998e, #38ef7d)',
                error: 'linear-gradient(to left, #d31027, #ea384d)'
            },
            background: {
                default: bgColor
            }
        },
        shape: {
            // borderRadius: 2
        },
    },
    faIR
);

export default theme;