import { Icon as Iconify } from "@iconify/react";
import {
    Box, Dialog, DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputBase, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Slide, styled, useMediaQuery,
    useTheme
} from "@mui/material";
import { useState } from "react";

import useAuth from "../auth/useAuth";
import useTaskbar from "../taskbar/useTaskbar";
import TaskIcon from "./TaskIcon";


const StyledSearchIcon = styled(
    props => <Iconify {...props} icon='solar:magnifer-linear' />
)(({ theme }) => ({
    color: theme.palette.text.secondary
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.button.fontSize
}))

const FilterForm = ({ value, onChange, view, onViewChange }) => {
    return (
        <Box sx={{ px: 2, py: 1, display: 'flex' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1
                }}
            >
                <StyledSearchIcon sx={{ mr: 1 }} color="inherit" />
                <StyledInputBase
                    placeholder="جستجو فرآیند..."
                    sx={{ flexGrow: 1 }}
                    value={value}
                    onChange={onChange}
                />
                {/* <IconButton onClick={() => onViewChange(view === 'list' ? 'grid' : 'list')}>
                    {view === 'list' ? (
                        <Tooltip title="نمایش شبکه‌ای">
                            <GridViewIcon />
                        </Tooltip>
                    ) : (
                        <Tooltip title="نمایش لیستی">
                            <ListViewIcon />
                        </Tooltip>
                    )}
                </IconButton> */}
            </Box>
        </Box >
    )
}

const GridView = ({ tasks, onClickTask, onToggleTask }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container alignItems='center' columns={{ xs: 3, sm: 4, md: 5 }} sx={{ py: 1 }}>
                {tasks.map((task, index) => (
                    <Grid item xs={1} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TaskIcon
                            {...task}
                            onClick={(e) => onClickTask(task)}
                            onClickToggle={(e) => onToggleTask(task)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}


const TaskListItem = ({ task, onClick, onClickToggle }) => {

    const { workspace } = useAuth();

    const pinned = workspace.membership.tabBarPins.includes(task.path);


    return (
        <>
            <ListItem
                disablePadding
                secondaryAction={
                    <IconButton
                        edge="end"
                        // disabled={user.currentWorkspace.tabBarPins.length > 4 && !pinned}
                        onClick={e => onClickToggle(task)}
                    >
                        <Iconify icon={pinned ? 'solar:pin-bold-duotone' : 'solar:pin-linear'} />
                    </IconButton>
                }
            >
                <ListItemButton onClick={e => onClick(task)}>
                    <ListItemAvatar>
                        <task.Icon sx={{ fontSize: '1.75rem' }} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={<strong>{task.title}</strong>}
                        primaryTypographyProps={{
                            sx: { cursor: 'pointer' }
                        }}
                    />
                </ListItemButton>
            </ListItem>
        </>
    )
}


const ListView = ({ tasks, onClickTask, onToggleTask }) => {
    return (
        <List sx={{ py: 1, flexGrow: 1, overflow: 'auto' }} dense>
            {tasks.map((task, index) => (
                <TaskListItem
                    task={task}
                    key={index}
                    onClick={onClickTask}
                    onClickToggle={onToggleTask}
                />
            ))}
        </List>
    )
}

const VIEWS_MAP = {
    'list': ListView,
    'grid': GridView
}


const TasksDialog = ({
    open,
    onClose,
    onClickTask
}) => {


    const { tasks, toggle } = useTaskbar();

    const [view, setView] = useState('grid');

    const [query, setQuery] = useState('');

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    const search = (task) => {
        if (!query) {
            return task;
        }

        if (task.title.match(`${query}.*`)) {
            return task;
        }

        if (task.keywords && task.keywords.length > 0) {
            if (task.keywords.filter(
                keyword => keyword.match(`${query}.*`)).length > 0
            ) {
                return task;
            }
        }

        return false;
    };

    const ViewComponent = VIEWS_MAP[view];


    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            scroll="paper"
            onClose={onClose}
            fullScreen={isMobile}
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'up'
            }}
            sx={theme => ({
                [theme.breakpoints.up('md')]: {
                    '& .MuiDialog-container': {
                        alignItems: 'flex-end'
                    },
                    '& .MuiDialog-paper': {
                        marginBottom: '55px'
                    }
                }
            })}
        // transitionDuration={0}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>همه فرآیندها</Box>
                <IconButton onClick={onClose}>
                    <Iconify icon='solar:close-circle-linear' />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent
                sx={{
                    height: { md: 500 },
                    p: 0,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                {!isMobile && (
                    <>
                        <FilterForm
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            view={view}
                            onViewChange={setView}
                        />
                        <Divider />
                    </>
                )}

                {tasks.filter(search).length > 0 ? (
                    <ViewComponent
                        tasks={
                            tasks.filter(
                                search
                            )
                        }
                        onClickTask={onClickTask}
                        onToggleTask={task => toggle(task.path)}
                    />
                ) : (
                    <Box sx={{ p: 5, textAlign: 'center', flexGrow: 1 }}>فرآیندی یافت نشد.</Box>
                )}

                {isMobile && (
                    <>
                        <Divider />
                        <FilterForm
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            view={view}
                            onViewChange={setView}
                        />
                    </>
                )}

            </DialogContent>
        </Dialog>
    )
}

export default TasksDialog