/**
 * The ceremonies reservation module
 * 
 * Responsible for:
 * 1. Issue reserve invoice
 * 4. Manage reserved invoices
 * 5. Manage products availability based on reserved
 */
import Module from '../../../foundation/Module';
import routes from './routes';
import components from './components';
import middlewares from './middlewares';
import hooks from './hooks';
import tasks from './tasks';


class ReservationModule extends Module {

    /**
     * The module name
     * 
     * @var string
     */
    name = 'Ceremonies/Reservation';

    /**
     * The module version
     * 
     * @var string
     */
    version = '1.0.0';

    /**
     * The module base path
     * 
     * use for routing 
     * 
     * @var string
     */
    basePath = '';

    /**
     * The module components
     * 
     * @var array
     */
    components = components;

    /**
     * The module hooks
     * 
     * @var Object
     */
    hooks = hooks;

    /**
     * Register The module
     * 
     * @return void
     */
    register() {
        this.registerMiddlewares(middlewares);
        this.registerTasks(tasks);
    }

    /**
     * Boot The module
     * 
     * @return void
     */
    boot() {
        this.registerRoutes(routes);
    }
}

export default ReservationModule;