import { lazy } from "react";
import Loadable from "../../components/Loadable";

const DomainsList = Loadable(lazy(() => import('./pages/DomainsList/Main')));


const routes = [
    {
        variant: "dashboard",
        path: '/domains',
        element: <DomainsList />,
        private: true,
        authorize: ({ hasRole, hasPermission }) => {
            return true;
            // if (
            //     membership.roles.map(r => r.identifier).includes('owner') ||
            //     membership.roles.map(r => r.identifier).includes('admin')
            // )
            //     return true;

            // return false;
        }
    },

    //
];

export default routes;