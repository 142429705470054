/* eslint-disable react-hooks/rules-of-hooks */
import CommentOutlined from "@mui/icons-material/CommentOutlined";
import BroadcastView from "../components/BroadcastView";


const hooks = {
    'Lawyers/Case-Management': {
        'routes.cases.details': () => {
            return {
                variant: "dashboard",
                private: true,
                path: 'broadcast',
                element: <BroadcastView
                    model="case"
                    extractModelId={params => params.caseId}
                />
            }
        },
        'CaseDetails.TabsList': ({ params }) => {
            return {
                label: 'اطلاع رسانی',
                value: `/case/${params.caseId}/broadcast`,
                icon: <CommentOutlined />,
                priority: 150,
            }
        },
    }
};

export default hooks;