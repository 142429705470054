import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../auth";


const Private = ({ children }) => {

    const auth = useAuth();
    const location = useLocation();

    if (!auth.user) {
        return (
            <Navigate
                to="/login"
                state={{ from: location }}
                replace
            />
        )
    }

    // if (auth.user.currentWorkspace.subscriptions.length < 1) {
    //     return (
    //         <Navigate
    //             to="/"
    //             state={{ from: location }}
    //             replace
    //         />
    //     )
    // }

    return children;
}

export default Private;