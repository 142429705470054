import Middleware from "../../../foundation/Middleware";
import axios from "axios";

class AuthenticatedMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Authenticated';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/self';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        getSelf: '/',
        getAccess: '/access',
        changePassword: '/password',
        verifyPassword: '/password/verify',
    }

    /**
     * Get authenticated user details
     * 
     * @returns response
     */
    async getSelf() {
        try {
            const response = await axios(this.paths.getSelf);
            return {
                ...response.data
            }
        } catch (error) {
            return {
                ...error.response.data
            }
        }
    }

    /**
     * Get authenticated user access
     * 
     * @returns response
     */
    async getAccess() {
        try {
            const response = await axios(this.paths.getAccess);
            return {
                ...response.data
            }
        } catch (error) {
            return {
                ...error.response.data
            }
        }
    }

    /**
     * Change password
     * 
     * @param {Object} data data provided
     * @param {string} data.phone user phone number
     * @param {string} data.password user password 
     * @returns response
     */
    async changePassword(data) {
        try {
            const response = await axios.post(this.paths.changePassword, data);
            return {
                ...response.data
            }
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }

    /**
     * Check if user has defined password
     * 
     * @param {Object} data data provided
     * @returns response
     */
    async verifyPassword() {
        try {
            const response = await axios.post(this.paths.verifyPassword);
            return {
                ...response.data
            }
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }
}

export default AuthenticatedMiddleware;