import Download from "@mui/icons-material/Download";
import FilePresentOutlined from "@mui/icons-material/FilePresentOutlined";
import Warning from "@mui/icons-material/Warning";
import { Avatar, Box, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Skeleton } from "@mui/material";
import format from "date-fns-jalali/format";
import { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useParams } from "react-router-dom";
import { useComponent, useMiddleware, useUtility } from "../../../Application";
import { useAuth } from "../../../auth";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import Text from "../../../components/Text";
import TextEditor from "../../../components/TextEditor";
import BroadcastForm from "./BroadcastForm";


const editorIsEmpty = data => data.replace(/<(.|\n)*?>/g, '').trim().length === 0;

const AttachmentListItem = ({ attachment }) => {

    const useStorageDownload = useUtility("Storage.useStorageDownload");
    const { download, progress, downloading } = useStorageDownload();

    const FilePreviewIcon = useComponent('storage.FilePreviewIcon');


    return (
        <ListItem
            secondaryAction={downloading ? <CircularProgressWithLabel value={progress} /> : (
                <IconButton
                    edge="end"
                    onClick={e => download(attachment)}
                >
                    <Download />
                </IconButton>
            )}
        >
            <ListItemAvatar>
                <FilePreviewIcon
                    DefaultIcon={<FilePresentOutlined fontSize="large" />}
                    file={attachment}
                />
            </ListItemAvatar>
            <ListItemText
                primary={<strong>{attachment.name}</strong>}
                primaryTypographyProps={{
                    overflow: 'hidden',
                    dir: 'ltr',
                }}
                secondary={`${String(attachment.ext).toUpperCase()} ${attachment.sizeFormatted}`}
                secondaryTypographyProps={{ dir: 'ltr' }}
            />
        </ListItem>
    )
}

const BroadcastView = ({
    model,
    extractModelId,
}) => {

    const { inject } = useAuth();
    const middleware = useMiddleware('Broadcast.Main');

    const params = useParams();

    const [infiniteScrollProps, setInfiniteScrollProps] = useState({
        page: 0,
        perPage: 25
    })

    const [messages, setMessages] = useState({
        data: [],
        total: '',
        hasNextPage: true
    });

    const loadMoreMessagesAsync = async () => {
        const response = await middleware.list(
            model,
            extractModelId(params),
            infiniteScrollProps
        );

        setMessages({
            total: response.total,
            data: [...messages.data, ...response.data],
            hasNextPage: [...messages.data, ...response.data].length < response.total
        });
    }

    useEffect(() => {
        loadMoreMessagesAsync();
    }, [infiniteScrollProps]);

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading: false,
        hasNextPage: messages.hasNextPage,
        onLoadMore: () => setInfiniteScrollProps({ ...infiniteScrollProps, page: infiniteScrollProps.page + 1 })
    });


    return (
        <Box sx={{ position: 'relative' }} ref={rootRef}>

            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={8}>

                    <Box sx={{ p: 2 }}>
                        <BroadcastForm
                            model={model}
                            extractModelId={extractModelId}
                            defaultValues={{ [model]: extractModelId(params) }}
                            onCreateOrUpdate={(data) => setMessages({ ...messages, data: [data.message, ...messages.data] })}
                        />
                    </Box>

                    {messages.data.length < 1 ? (
                        <Box sx={{ px: 5, py: 15, textAlign: "center" }}>
                            <Warning fontSize="large" />
                            <p>موردی جهت نمایش وجود ندارد.</p>
                        </Box>
                    ) : messages.data.map((message, index) => (
                        <Box sx={{ mb: 3 }} key={index}>
                            <Divider>
                                <Paper component='small' variant="outlined" sx={{ px: 2, py: 0.25, borderRadius: 10 }}>
                                    <Text>{format(new Date(message.sentAt), "dd MMMM yyyy HH:mm")}</Text>
                                </Paper>
                            </Divider>
                            <Box sx={{ py: 2 }}>
                                {message.content && !editorIsEmpty(message.content) ? (
                                    <Box sx={{ px: { xs: 2, sm: 4 } }}>
                                        <TextEditor
                                            readOnly
                                            value={message.content}
                                            modules={{ toolbar: false }}
                                        />
                                    </Box>
                                ) : null}
                                {message.attachments.length > 0 && (
                                    <Paper variant="outlined" sx={{ mx: { xs: 2, sm: 4 }, mt: 2, maxWidth: 400 }}>
                                        <List disablePadding dense>
                                            {message.attachments.map((attachment, index) => (
                                                <AttachmentListItem
                                                    {...attachment}
                                                    key={index}
                                                />
                                            ))}
                                        </List>
                                    </Paper>
                                )}
                            </Box>
                        </Box>
                    ))}
                    {(messages.total > 0 && messages.hasNextPage) && (
                        <Box sx={{ py: 2 }}>
                            <Box sx={{ px: { xs: 2, sm: 4 } }} ref={sentryRef}>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </Box>
                            <Paper variant="outlined" sx={{ mx: { xs: 2, sm: 4 }, mt: 2, maxWidth: 400 }}>
                                <List disablePadding dense>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar variant="rounded">
                                                <Skeleton variant="rectangular" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Skeleton variant="text" />}
                                            secondary={<Skeleton variant="text" width="40%" />}
                                        />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default BroadcastView;