import axios, { AxiosError } from "axios";
import Middleware from "../../../foundation/Middleware";

class ContractsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Contracts';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        transactions: '/contract/:contractId/trasactions',
        isSettled: '/contract/:contractId/isSettled',
        totalPaid: '/contract/:contractId/totalPaid',
        remaining: '/contract/:contractId/remaining',
        settle: '/contract/:contractId/settle',
        addPayment: '/contract/:contractId/payments',
        //
    }

    /**
     * Display a list of all transactions of contract
     *  
     * @return Promise
     */
    async transactions(contractId, args) {
        try {
            const response = await axios(
                this.serializePath(
                    this.replaceParams(this.paths.transactions, { contractId }),
                    args
                )
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get contract settled status
     *  
     * @return Promise
     */
    async isSettled(contractId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.isSettled, { contractId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get contract total paid
     *  
     * @return Promise
     */
    async totalPaid(contractId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalPaid, { contractId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get contract settled status
     *  
     * @return Promise
     */
    async remaining(contractId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.remaining, { contractId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Settle all transactions of a contract
     *  
     * @return Promise
     */
    async settle(contractId) {
        try {
            const response = await axios.post(
                this.replaceParams(this.paths.settle, { contractId })
            );
            return { ...response.data };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Add new payment for the contract
     *  
     * @return Promise
     */
    async addPayment(contractId, data) {
        try {
            const response = await axios.post(
                this.replaceParams(this.paths.addPayment, { contractId }),
                data
            );
            return { ...response.data };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    //
}

export default ContractsMiddleware;