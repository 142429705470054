import { Icon as Iconify } from "@iconify/react";
import DoneAll from "@mui/icons-material/DoneAll";
import { Avatar, Badge, Box, Button, Divider, IconButton, List, ListItem, ListItemText, Popover, Typography } from "@mui/material";
import format from "date-fns-jalali/format";
import formatDistance from "date-fns-jalali/formatDistance";
import isAfter from "date-fns-jalali/isAfter";
import subDays from "date-fns/subDays";
import { useEffect, useState } from "react";

import { useApplication, useMiddleware } from "../../../Application";


const displayNotificationTime = (notification) => {
    const dateCreated = new Date(notification.createdAt);
    const isAfterThreeDays = isAfter(dateCreated, subDays(new Date(), 3));
    return isAfterThreeDays ?
        formatDistance(new Date(notification.createdAt), new Date()) :
        format(new Date(notification.createdAt), "dd MMMM yyyy HH:mm");
}

const NotificationMenu = () => {


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget)

    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl);
    const id = open ? 'notifications-menu' : undefined;

    const mainMiddleware = useMiddleware('notifications.main');
    const authMiddleware = useMiddleware('notifications.authenticated');

    const [isLoading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);

    const loadDataAsync = async () => {
        const response = await authMiddleware.list({ unread: true });
        if (response.ok) {
            setNotifications(response.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        loadDataAsync();
    }, []);

    const { application } = useApplication();

    useEffect(() => {
        application.socket.on('notifications:notificationCreated', loadDataAsync);

        return () => {
            application.socket.off('notifications:notificationCreated', loadDataAsync);
        };
    }, []);


    const [isMarkingAll, setMarkingAll] = useState(false);
    const markAllRead = async () => {
        setMarkingAll(true)
        const response = await authMiddleware.markAllRead();
        if (response.ok) {
            loadDataAsync();
            setMarkingAll(false);
        }
    }


    return (
        <>
            <IconButton
                color="default"
                onClick={handleClick}
            >
                <Badge
                    variant="dot"
                    color="primary"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    invisible={notifications.length < 1}
                >
                    <Iconify icon="solar:bell-line-duotone" />
                </Badge>
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        minWidth: 350
                    }
                }}
            >
                <Box sx={{ display: 'flex', px: 2, py: 1, alignItems: 'center' }}>
                    <Typography variant="button" sx={{ fontSize: 14 }}>پیام‌های من</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {notifications.length > 0 && (
                        <Button
                            size="small"
                            startIcon={<DoneAll />}
                            onClick={markAllRead}
                            disabled={isMarkingAll}
                        >
                            خواندن همه
                        </Button>
                    )}
                </Box>
                <Divider />
                {notifications.length > 0 ? (
                    <List>
                        {notifications.map((notification) => (
                            <ListItem
                                selected={!Boolean(notification.readAt)}
                                key={notification.id}
                                secondaryAction={
                                    <Avatar>
                                        A
                                    </Avatar>
                                }
                            >
                                <ListItemText
                                    primary={JSON.parse(notification.data).title['fa-IR']}
                                    secondary={displayNotificationTime(notification)}
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Box sx={{ py: 5, textAlign: "center" }}>
                        <p>پیامی وجود ندارد.</p>
                    </Box>
                )}
            </Popover>
        </>
    )
}

export default NotificationMenu;