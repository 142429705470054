import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMiddleware } from "../../../Application";


const ClientSettleConfirmation = ({ open, onClose, onSettle, person = {} }) => {

    const middleware = useMiddleware('transactions.clients');

    const { enqueueSnackbar } = useSnackbar();

    const settleAsync = async () => {
        try {
            const response = await middleware.settle(person.id);
            if (response.ok) {
                enqueueSnackbar(`شخص تسویه شد.`);
                onClose();
                onSettle(response.invoice);
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="settle-dialog-title">
                تایید تسویه کامل شخص
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="settle-dialog-description">
                    تایید تسویه کامل شخص {person.fullName}. تمامی پرداختی‌ها و دریافتی‌ها صفر می‌شوند.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={settleAsync} variant="contained" color="primary" autoFocus>تسویه کامل</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={onClose}>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClientSettleConfirmation;