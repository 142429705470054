import axios from "axios";
import AES from "crypto-js/aes";
import ENCUTF8 from "crypto-js/enc-utf8";
import setWith from "lodash/setWith";
import clone from "lodash/clone";
import forOwn from "lodash/forOwn";
import omit from "lodash/omit";
import { useEffect, useState } from "react";
import { useApplication, useMiddleware } from "../Application";
import AuthContext from "./AuthContext";


const AuthProvider = ({ children }) => {

    const { application } = useApplication();
    const middleware = useMiddleware('authentication.authenticated');

    const localToken = localStorage.getItem('OAUTH-TOKEN');

    const [isLoading, setLoading] = useState(Boolean(localToken));

    const initialState = { user: null, workspace: null, access_fetched: false };

    const [auth, setAuth] = useState(initialState);


    const getAuthToken = () => {
        const bytes = AES.decrypt(localStorage.getItem('OAUTH-TOKEN'), 'AUTH-SECRET');
        return bytes.toString(ENCUTF8);
    }

    const loadAccessAsync = async () => {
        const response = await middleware.getAccess();
        if (response.ok) {
            setAuth({
                ...auth,
                workspace: {
                    ...auth.workspace,
                    membership: {
                        ...auth.workspace.membership,
                        ...omit(response, 'ok')
                    }
                },
                access_fetched: true
            });
        }
    }

    const loadAuthenticatedAsync = async () => {
        const response = await middleware.getSelf();
        if (response.ok) {
            axios.defaults.headers.common['Workspace-ID'] = response.workspace.id;
            // await loadAccessAsync(omit(response, 'ok'));
            setAuth(omit(response, 'ok'));
        }
        setLoading(false);
    }

    useEffect(() => {
        if (localToken) {
            const token = getAuthToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            loadAuthenticatedAsync();
        }
    }, [localToken])

    const login = (data) => {
        localStorage.setItem('OAUTH-TOKEN', AES.encrypt(data.token, 'AUTH-SECRET').toString());
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
        axios.defaults.headers.common['Workspace-ID'] = data.workspace.id;
        setAuth(data);
    }

    const logout = () => {
        localStorage.removeItem('OAUTH-TOKEN');
        axios.defaults.headers.common['Authorization'] = undefined;
        axios.defaults.headers.common['Workspace-ID'] = undefined;
        setAuth(initialState);
    }

    const update = (path, state) => setAuth(setWith(clone(auth), path, state, clone));

    /** helper methods */
    const hasRole = role => auth.workspace.membership.roles.map(r => r.identifier).includes(role);
    const hasAllRoles = roles => roles.every(role => auth.workspace.membership.roles.map(r => r.identifier).includes(role));
    const hasAnyRole = roles => roles.some(role => auth.workspace.membership.roles.map(r => r.identifier).includes(role));
    const hasPermission = permission => auth.workspace.membership.permissions.includes(permission);
    const hasAllPermissions = permissions => permissions.every(permission => auth.workspace.membership.permissions.includes(permission));
    const hasAnyPermission = permissions => permissions.some(permission => auth.workspace.membership.permissions.includes(permission));


    const getInjections = (moduleName) => {
        const workspaceModuleIdentifiers = auth.workspace.subscriptions.map(s => s.subscribed.identifier);
        const workspaceModules = application.modules.filter(module => workspaceModuleIdentifiers.includes(String(module.name).toLowerCase()));
        let injections = {};
        workspaceModules.forEach((module) => {
            if (module.hooks) {
                forOwn(module.hooks, (value, key) => {
                    if (String(key).toLowerCase() === String(moduleName).toLowerCase()) {
                        forOwn(value, (value, key) => {
                            injections = {
                                ...injections,
                                [key]: [...(injections[key] || []), value]
                            }
                        })
                    }
                })
            }
        })
        return injections;
    }

    const inject = (moduleName, hookName, args) => {
        const injections = getInjections(moduleName);
        if (
            Object.keys(injections).length === 0 ||
            Object.keys(injections).map(
                hook => String(hook).toLowerCase() === String(hookName).toLowerCase()
            ).filter(Boolean).length < 1
        ) return null;

        return injections[hookName].map(
            (render, index) => render({ ...args, key: index })
        );
    }

    useEffect(() => {
        if (auth.user) {
            application.socket.emit('authentication:login', auth);
        } else {
            application.socket.emit('authentication:logout');
        }
    }, [auth.user])

    useEffect(() => {
        if (auth.user && auth.workspace && !auth.access_fetched) {
            loadAccessAsync();
        }
    }, [auth.user]);

    // useEffect(() => {
    //     if (auth.user && auth.workspace && auth.workspace.membership.homePath) {
    //         window.location.href = auth.workspace.membership.homePath;
    //     }
    // }, [auth.user])


    if (isLoading) return null;

    return (
        <AuthContext.Provider
            value={{
                ...auth,
                login,
                logout,
                inject,
                getAuthToken,
                update,
                hasRole,
                hasAnyRole,
                hasAllRoles,
                hasPermission,
                hasAnyPermission,
                hasAllPermissions,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;