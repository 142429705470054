import { Icon as Iconify } from "@iconify/react";
import { Box, Divider, IconButton, AppBar as MuiAppBar, Toolbar, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useAuth } from "../auth";
import Tooltip from "../components/Tooltip";
import useTaskbar from "../taskbar/useTaskbar";
import useNavigateTask from "../utils/useNavigateTask";
import DateTime from "./DateTime";

const TaskIconButton = styled(IconButton, {
    shouldForwardProp: prop => prop !== "active"
})(({ theme, active }) => ({
    position: 'relative',
    opacity: active ? 1 : 0.65,
    '&:after': {
        transition: theme.transitions.create('width'),
        content: `""`,
        position: 'absolute',
        width: active ? '15px' : 0,
        height: '3px',
        bottom: 2,
        borderRadius: '15px',
        backgroundColor: '#fff'
    },
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    top: 'auto',
    bottom: 0,
    color: 'rgba(255,255,255,0.8)',
    backgroundColor: "rgba(0,0,0,0.2)",
    border: 'none',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    "&:before": {
        content: `""`,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(10px)',
    }
}));

const ToolbarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    position: 'relative',
}));

const ToolbarRight = styled(Box)(({ theme }) => ({
    display: 'none',
    position: 'absolute',
    height: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
    }
}));

const BottomToolbarTaskItem = ({ task, onClick, className }) => {

    const location = useLocation();

    return (
        <Tooltip title={task.title} key={task.path}>
            <TaskIconButton
                color="inherit"
                onClick={onClick}
                className={className}
                active={location.pathname === task.path}
            >
                <task.Icon color="inherit" />
            </TaskIconButton>
        </Tooltip>
    )
}

const FullscreenButton = () => {

    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    const elem = document.documentElement;

    const openFullscreen = () => {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }


    return (
        <Tooltip title="باز/بستن تمام‌ صفحه">
            <IconButton
                color="inherit"
                onClick={isFullscreen ? closeFullscreen : openFullscreen}
                sx={{ p: .5 }}
            >
                <Iconify 
                    icon={isFullscreen ? 'solar:quit-full-screen-square-line-duotone' : 'solar:full-screen-square-line-duotone'}
                    fontSize={28}
                />
            </IconButton>
        </Tooltip>
    )
}

const BottomToolbar = ({ onTasksButtonClicked }) => {

    const { workspace } = useAuth();
    const { tasks } = useTaskbar();

    const location = useLocation();
    const navigate = useNavigateTask();


    return (
        <>
            <AppBar
                component="div"
                position="fixed"
                variant="outlined"
                color="inherit"
                elevation={0}
            >
                <Toolbar variant="dense">
                    <ToolbarContainer>
                        <ToolbarRight>
                            <DateTime />
                            <FullscreenButton />
                            
                            <Tooltip title="شخصی‌سازی">
                                <IconButton
                                    color="inherit"
                                    LinkComponent={Link}
                                    to="#customize"
                                    sx={{ p: .5 }}
                                >
                                    <Iconify 
                                        icon={'solar:pallete-2-line-duotone'}
                                        fontSize={28}
                                    />
                                </IconButton>
                            </Tooltip>
                        </ToolbarRight>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: 'flex-start', md: 'center' },
                                alignItems: 'center',
                                flex: 1,
                                overflow: 'auto'
                            }}
                            className="tasks-container"
                        >
                            <Tooltip title="همه فرآیندها">
                                <IconButton
                                    color="inherit"
                                    // className="all-tasks"
                                    onClick={onTasksButtonClicked}
                                    sx={{ p: .5 }}
                                >
                                    <Iconify icon='solar:code-scan-bold-duotone' fontSize={28}/>
                                </IconButton>
                            </Tooltip>
                            {workspace.membership.tabBarPins && workspace.membership.tabBarPins.length > 0 ?
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    variant="middle"
                                    sx={{ borderColor: 'rgba(255, 255, 255, 0.5)', borderRightWidth: '2px' }}
                                /> : null}
                            {workspace.membership.tabBarPins && workspace.membership.tabBarPins.length > 0 ? (
                                <Box sx={{ display: 'flex' }}>
                                    {workspace.membership.tabBarPins.map((pin, idx) => {
                                        const task = tasks.find(t => t.path === pin);
                                        return task && (
                                            <BottomToolbarTaskItem
                                                key={idx}
                                                task={task}
                                                className={task.path === '/products' ? 'task-manage-products' : ''}
                                                onClick={() => navigate(task)}
                                            />
                                        )
                                    })}
                                </Box>
                            ) : null}
                        </Box>
                    </ToolbarContainer>
                </Toolbar>
            </AppBar>
            <Toolbar variant="dense" />
        </>
    )
}

export default BottomToolbar;