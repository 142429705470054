import ManageAccountsOutlined from "@mui/icons-material/ManageAccountsOutlined";


const tasks = {
    settings: {
        title: `تنظیمات حساب`,
        Icon: ManageAccountsOutlined,
        path: `/account/settings`,
        subtasks: []
    },
}

export default tasks;