import MuiCircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const CircularProgressWithLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <MuiCircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="button"
                    component="div"
                    color="text.secondary"
                    sx={{ fontWeight: 900 }}
                ><small>{`${Math.round(props.value)}%`}</small></Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;