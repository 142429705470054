import CloseIcon from "@mui/icons-material/CloseOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import ResponsiveDialog from "./ResponsiveDialog";


const ConfirmDialog = ({
    open,
    onClose,
    dialogProps = {},
    title,
    content,
    confirmText = 'تایید',
    confirmIcon,
    confirmButtonProps = {},
    cancelText = 'انصراف',
    cancelButtonProps = {},
    onConfirm,
    loading
}) => {

    const ConfirmButton = confirmIcon ? LoadingButton : Button;

    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs",
                ...dialogProps
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{title}</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {typeof content == "string" ? (
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                ) : content}
            </DialogContent>
            <DialogActions>
                <ConfirmButton
                    onClick={onConfirm}
                    startIcon={confirmIcon}
                    loading={loading}
                    disabled={loading}
                    variant="contained"
                    {...confirmButtonProps}
                >
                    {confirmText}
                </ConfirmButton>
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={onClose} {...cancelButtonProps}>{cancelText}</Button>
            </DialogActions>
        </ResponsiveDialog>
    )
}

ConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dialogProps: PropTypes.object,
    title: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]).isRequired,
    content: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]).isRequired,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    confirmButtonProps: PropTypes.object,
    cancelButtonProps: PropTypes.object
}

export default ConfirmDialog