import axios from "axios";
import flatten from "lodash/flatten";
import forOwn from "lodash/forOwn";
import map from "lodash/map";
import pick from "lodash/pick";
import io from 'socket.io-client';


class Application {

    /**
     * The application host
     *
     * @var string
     */
    host;

    /**
     * The application host
     *
     * @var string
     */
    hostSocketIO;

    /**
     * All of the registered modules
     *
     * @var array
     */
    modules = [];

    /**
     * All of the registered routes
     *
     * @var array
     */
    routes = [];

    /**
     * All of the registered routes
     *
     * @var array
     */
    socket;

    /**
     * create the application instance
     *
     * @param config Object
     * @return void
     */
    constructor(config) {

        axios.defaults.headers.common['Accept'] = 'application/json';

        Object.assign(this, pick(config, [
            'version',
            'host',
            'hostSocketIO'
        ]));

        this.socket = io(config.hostSocketIO, {
            path: '/socket.io',
            transports: ['websocket'],
            secure: true,
        });

        config.modules.forEach(Module => {
            this.registerModule(Module);
        })

        this.registerHooks();

        this.modules.map(module => module.boot());

        this.registerRoutes();
    }

    /**
     * Register the application modules
     *
     * @return void
     */
    registerModule(Module) {
        const module = new Module(this);
        module.register();
        this.modules = [...this.modules, module];
    }

    /**
     * Register the application routes
     *
     * @var array
     */
    registerRoutes() {
        this.routes = flatten(
            map(
                this.modules,
                module => module.routes
                // module => module.name !== 'Authentication' && module.routes
            )
        )
    }

    /**
     * Register the application modules hooks
     *
     * @return void
     */
    registerHooks() {
        const self = this;
        this.modules.forEach((module) => {
            if (module.hooks) {
                forOwn(module.hooks, (value, key) => {
                    if (self.module(key)) {
                        self.module(key).registerInjections(value);
                    }
                })
            }
        })
    }

    /**
     * Get the application specific module
     *
     * @param string modulename
     * @return Module
     */
    module(moduleName) {
        return this.modules.filter(
            module => String(module.name).toLowerCase() === String(moduleName).toLowerCase()
        )[0]
    }
}

export default Application;