// import ProductsIconOutlined from "@mui/icons-material/Bell";

const tasks = {
    // history: {
    //     title: 'تاریخچه اعلامیه‌ها',
    //     Icon: ProductsIconOutlined,
    //     path: `/notifications`,
    //     keywords: [],
    //     default: true,
    //     subtasks: []
    // }
}

export default tasks;