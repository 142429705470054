import CloseIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment
} from "@mui/material";
import forOwn from "lodash/forOwn";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useComponent, useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";
import NumberTextField from "../../../components/NumberTextField";
import TextField from "../../../components/TextField";
import useDialog from "../../../utils/useDialog";


const AccountFormDialog = ({
    open,
    onClose,
    account,
    onCreateOrUpdate
}) => {

    const middleware = useMiddleware('personal-accounting.accounts');

    const { inject } = useAuth();

    const CategoriesDialog = useComponent('Categories.CategoriesDialog');

    const defaultValues = {
        code: '',
        name: '',
        description: '',
        category: '',
        ...(inject('accounts', 'AccountFormDialog.defaultValues'))
    }

    const { control, register, handleSubmit, formState: { errors }, setValue, reset, setError } = useForm({
        defaultValues
    });

    const category = useWatch({ control, name: "category" });

    const [isCreatingOrUpdating, setCreatingOrUpdating] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!open) {
            const timer = setTimeout(() => {
                reset(defaultValues);
            }, 500);

            return () => {
                clearTimeout(timer);
            }
        } else {
            if (account) reset(account);
        }
    }, [open, account])


    const _createOrUpdateAsync = async (data) => {
        setCreatingOrUpdating(true);

        const res = await middleware.createOrUpdate(data);
        if (res.ok) {
            enqueueSnackbar(`حساب ${account ? 'بروزرسانی' : 'جدید ثبت'} گردید.`);
            onClose()
            onCreateOrUpdate(account)
            reset(defaultValues)
        } else {
            if (res.status === 422) {
                forOwn(res.errors, (value, key) => {
                    setError(key, { type: "manual", message: value[0] })
                })
            }
        }
        setCreatingOrUpdating(false);
    }

    const {
        isOpen: isCategoryDialogOpen,
        isOpening: isCategoryDialogOpening,
        open: openCategoryDialog,
        close: closeCategoryDialog,
        data: categoryDialogCategory
    } = useDialog();


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{account ? 'بروزرسانی مشخصات حساب' : 'ثبت مشخصات حساب'}</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth={true}
                            size="small"
                            label="نام حساب"
                            variant="filled"
                            required
                            autoFocus
                            error={errors.name && true}
                            {...register("name", { required: true })}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth={true}
                            size="small"
                            label="گروه حساب"
                            variant="filled"
                            value={category ? category.name : ''}
                            onChange={() => { }}
                            inputProps={{
                                readOnly: true
                            }}
                            onFocus={() => openCategoryDialog()}
                            onClick={() => openCategoryDialog()}
                            onKeyDown={e => e.keyCode === 13 && openCategoryDialog()}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Controller
                            name="initialAmount"
                            control={control}
                            render={
                                ({ field }) => (
                                    <NumberTextField
                                        fullWidth={true}
                                        size="small"
                                        label="مقدار اولیه"
                                        dir="ltr"
                                        variant="filled"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>
                                        }}
                                        error={Boolean(errors.initialAmount)}
                                        {...field}
                                    />
                                )
                            }
                        />
                    </Grid>
                    {inject('accounts', 'AccountFormDialog.Fields', { control, register, errors })}
                </Grid>
            </Box>
            <Divider />
            <DialogActions>
                <LoadingButton
                    loading={isCreatingOrUpdating}
                    loadingPosition="start"
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit(_createOrUpdateAsync)}
                >
                    ثبت مشخصات
                </LoadingButton>
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>

            <CategoriesDialog
                open={isCategoryDialogOpen}
                onClose={closeCategoryDialog}
                selectable={true}
                label="personal-accounting-account"
                onClear={() => {
                    setValue("category", '');
                    closeCategoryDialog();
                }}
                onSelect={category => {
                    setValue('category', category);
                    closeCategoryDialog();
                }}
                selected={category}
            />
        </Dialog>
    )
}

export default AccountFormDialog