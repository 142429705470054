import NoResultItem from "@mui/icons-material/Warning";
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogTitle, Divider, Link as MuiLink, styled, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMiddleware } from "../../../../Application";
import DetailedDate from "../../../../components/DetailedDate";
import TableLoader from "../../../../components/TableLoader";


const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.action.hover : theme.palette.common.white,
    '&:focus': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const InvoicesTable = forwardRef(({
    loading,
    invoices
}, ref) => {

    const navigate = useNavigate();
    const location = useLocation();


    if (loading) {
        return (
            <TableLoader size="small" />
        )
    }

    if (invoices.length < 1) {
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <NoResultItem fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )
    }

    return (
        <TableContainer
            sx={{ overflow: "auto", height: 300 }}
        >
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">تاریخ ثبت</TableCell>
                        <TableCell align="center">تاریخ رزرو</TableCell>
                        <TableCell align="center">صورت‌حساب</TableCell>
                        <TableCell width={150}>مشتری</TableCell>
                        <TableCell align="center">رزرو شده</TableCell>
                        <TableCell align="center">برگشتی</TableCell>
                        <TableCell align="center">خسارتی</TableCell>
                        <TableCell align="center">مانده</TableCell>
                        <TableCell align="center">تاریخ برگشت</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoices.map((item, index) => (
                        <StyledTableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center"><DetailedDate date={item.invoice.createdAt} /></TableCell>
                            <TableCell align="center"><DetailedDate date={item.invoice.reservedAt} /></TableCell>
                            <TableCell align="center">
                                <MuiLink
                                    color="inherit"
                                    onClick={() => navigate(
                                        '/invoice/'.concat(item.invoice.id),
                                        { state: { prev: location } }
                                    )}
                                >
                                    {item.invoice.number}
                                </MuiLink>
                            </TableCell>
                            <TableCell>
                                <MuiLink
                                    color="inherit"
                                    onClick={() => navigate(
                                        '/client/'.concat(item.invoice.client.id),
                                        { state: { prev: location } }
                                    )}
                                >
                                    {item.invoice.client.fullName}
                                </MuiLink>
                            </TableCell>
                            <TableCell align="center">{item.reservedQuantity}</TableCell>
                            <TableCell align="center">{item.totalReturned}</TableCell>
                            <TableCell align="center">{item.totalDamaged}</TableCell>
                            <TableCell align="center">{item.currentReserved}</TableCell>
                            <TableCell align="center">{item.invoice.isReturned ? <DetailedDate date={item.invoice.returnedAt} /> : '-'}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
})


const ProductInvoicesDialog = ({
    open,
    onClose,
    product
}) => {

    const middleware = useMiddleware('ceremonies/reservation.products');

    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);


    const _loadInvoicesAsync = async () => {
        const response = await middleware.invoices(product.id);
        if (response.ok) {
            setInvoices(response.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open && product) {
            _loadInvoicesAsync();
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                تاریخچه سفارشات محصول
            </DialogTitle>
            <Divider />
            <InvoicesTable
                loading={loading}
                invoices={invoices}
            />
            <Divider />
            <DialogActions>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>بستن</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProductInvoicesDialog