import { useState } from "react";
import { useMiddleware } from "../../../Application";


const useStorageUpload = ({ extraFormData, onComplete, onError } = {
    extraFormData: {},
    onComplete: file => { },
    onError: error => { }
}) => {

    const middleware = useMiddleware('Storage.Main');

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const upload = async (file) => {

        // start loading
        setUploading(true);

        // create formData
        const formData = new FormData();
        formData.append('file', file);

        Object.keys(extraFormData).map(key => {
            formData.append(key, extraFormData[key]);
        });

        // post file to the server
        const response = await middleware.upload(formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        });

        // stop loading
        setUploading(false);

        // dispatch onComplete event
        onComplete(response.file);
    }

    return { upload, progress, uploading };
}

export default useStorageUpload