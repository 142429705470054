/**
 * Display a number as comma seperated price
 * @param number the number being displayed
 * @return string
 */
function pricify(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default pricify;
