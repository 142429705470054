import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import {
    Box,
    Button, DialogActions,
    DialogContent,
    DialogTitle, Divider, IconButton, InputAdornment, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMiddleware } from "../../../Application";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import ResponsiveTable from "../../../components/ResponsiveTable";
import TextField from "../../../components/TextField";
import useDebounce from '../../../utils/useDebounce';
import useGetRowNumber from "../../../utils/useGetRowNumber";
import useResponsiveTableFetch from "../../../utils/useResponsiveTableFetch";
import PricingDeleteDialog from "./PricingDeleteDialog";
import PricingFormDialog from "./PricingFormDialog";


const PricingTable = ({
    loading,
    data,
    meta,
    selected,
    onSelect,
    selectable,
    onClickEdit,
    onClickDelete,
    infiniteScrollProps,
    page,
    perPage,
    onPageChange,
    onPerPageChange
}) => {

    const getRowNumber = useGetRowNumber();

    const isSelected = (price) => {
        if (!selectable || !selected)
            return false;

        if (Array.isArray(selected))
            return selected.filter(m => m.id === price.id).length > 0;

        return selected.id === price.id;
    }


    return (
        <ResponsiveTable
            data={data}
            loading={loading}
            infiniteScrollProps={infiniteScrollProps}
            definitions={[
                (selectable ?
                    {
                        headerCellProps: {
                            align: "center"
                        },
                        rowCellProps: {
                            align: "center",
                            width: 25
                        },
                        renderCell: (row, index) => (
                            <div>
                                {isSelected(row) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            </div>
                        ),
                    } : {}),
                {
                    title: 'ردیف',
                    headerCellProps: {
                        align: "center"
                    },
                    rowCellProps: {
                        align: "center"
                    },
                    renderCell: (row, index) => getRowNumber(index)
                },
                {
                    field: 'name',
                    title: 'تعرفه',
                    renderList: row => <strong>{row.name}</strong>,
                }
            ]}
            renderSheetTitle={row => row.name}
            renderListItemChildren={(row, index) => selectable ? (
                <ListItemButton
                    key={index}
                    selected={isSelected(row)}
                    onClick={() => selectable && onSelect(row)}
                >
                    <ListItemIcon>
                        {isSelected(row) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    </ListItemIcon>
                    <ListItemText
                        primary={<strong>{row.name}</strong>}
                    />
                </ListItemButton>
            ) : (
                <>
                    <ListItemAvatar>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <strong>{getRowNumber(index)}</strong>
                        </Box>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<strong>{row.name}</strong>}
                    />
                </>
            )}
            tableRowProps={row => ({
                hover: true,
                striped: !selectable,
                selected: isSelected(row),
                onClick: e => selectable && onSelect(row)
            })}

            paginationProps={{
                count: meta.total,
                rowsPerPage: perPage || 25,
                page: page || 0,
                onPageChange: (e, page) => onPageChange(page),
                onRowsPerPageChange: e => onPerPageChange(e.target.value)
            }}

            actions={!selectable && [
                { title: 'ویرایش', icon: <EditIcon />, onClick: onClickEdit },
                { title: 'حذف', icon: <DeleteIcon />, onClick: onClickDelete }
            ]}

            listItemProps={{
                disablePadding: selected
            }}

            listProps={{
                disablePadding: !selected
            }}
        />
    )
}

const PricingsDialog = ({
    open,
    onClose,
    selectable,
    value,
    onChange,
    onClear,
    requestConfig,
}) => {

    const middleware = useMiddleware('Pricing.Main');

    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 500);

    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        perPage: 25
    })

    const { data, total, loading, loadMore, reload } = useResponsiveTableFetch({
        handleFetch: params => middleware.list({
            ...params,
            ...paginationParams,
            query: debouncedQuery
        }, requestConfig),
        shouldLoadData: open,
        loadDeps: [open, paginationParams, debouncedQuery],
        infiniteScrollDefaultParams: paginationParams
    });


    const { enqueueSnackbar } = useSnackbar();
    const [formDialogProps, setFormDialogProps] = useState({ open: false });
    const handleCreateOrUpdate = (message) => {
        enqueueSnackbar(message);
        setFormDialogProps({ open: false });
        reload();
    }

    const [deleteDialogProps, setDeleteDialogProps] = useState({ open: false });
    const handleDelete = () => {
        enqueueSnackbar('تعرفه حذف گردید');
        setDeleteDialogProps({ open: false });
        reload();
    }


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{selectable ? 'انتخاب تعرفه' : 'مدیریت تعرفه‌ها'}</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 0 }}>
                <Box sx={{ p: 2 }}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth={true}
                        size="small"
                        variant="filled"
                        label="جستجو براساس نام"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        // autoFocus
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        }}
                    />
                </Box>
                <PricingTable
                    loading={loading}
                    data={data}
                    meta={{ total }}
                    selectable={selectable}
                    selected={value}
                    onSelect={onChange}
                    infiniteScrollProps={{
                        loading: false,
                        hasNextPage: data.length < total,
                        onLoadMore: loadMore
                    }}
                    page={paginationParams.page}
                    perPage={paginationParams.perPage}
                    onPageChange={page => setPaginationParams({ ...paginationParams, page })}
                    onPerPageChange={perPage => setPaginationParams({ ...paginationParams, perPage })}
                    onClickEdit={price => setFormDialogProps({
                        open: true,
                        defaultValues: price,
                        onCreateOrUpdate: price => handleCreateOrUpdate('تعرفه بروزرسانی گردید.')
                    })}
                    onClickDelete={price => setDeleteDialogProps({ open: true, price })}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                {selectable && (
                    <>
                        <Button type="button" onClick={onClose}>بستن</Button>
                        <Box sx={{ flexGrow: 1 }}></Box>
                    </>
                )}
                <Button
                    type="button"
                    onClick={() => setFormDialogProps({ open: true, onCreateOrUpdate: price => handleCreateOrUpdate('تعرفه جدید ثبت گردید.') })}
                    variant="contained"
                >
                    ثبت جدید
                </Button>
                {onClear && <Button type="button" onClick={onClear}>پاک کردن</Button>}
                {!selectable && (
                    <>
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <Button type="button" onClick={onClose}>انصراف</Button>
                    </>
                )}
            </DialogActions>

            <PricingFormDialog
                {...formDialogProps}
                onClose={() => setFormDialogProps({ open: false })}
                requestConfig={requestConfig}
            />

            <PricingDeleteDialog
                {...deleteDialogProps}
                onClose={() => setDeleteDialogProps({ open: false })}
                onDelete={handleDelete}
                requestConfig={requestConfig}
            />
        </ResponsiveDialog>
    )
}

export default PricingsDialog