import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useMiddleware } from "../../../Application";
import { useSnackbar } from 'notistack';


const CategoryDeleteDialog = ({
    open,
    category,
    onClose,
    onDelete
}) => {

    const middleware = useMiddleware('categories.main');

    const { enqueueSnackbar } = useSnackbar();

    const deleteCategoryAsync = async () => {
        try {
            const { ok } = await middleware.delete(category.id);
            if (ok) {
                enqueueSnackbar('گروه حذف گردید.');
                onClose();
                onDelete();
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                حذف گروه
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    تایید حذف گروه {category?.name} بطور کامل
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={deleteCategoryAsync} variant="contained" color="error">تایید و حذف</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={onClose} autoFocus>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CategoryDeleteDialog