import { Box, Typography } from "@mui/material";
import DataList from "./DataList";


const DataListBox = ({ title, data = [], loading }) => {
    return (
        <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
            <Typography variant="h6" sx={{ mb: 3 }}>{title}</Typography>
            <DataList data={data.map(dl => ({ ...dl, loading }))} />
        </Box>
    )
}

export default DataListBox;