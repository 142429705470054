import CloseIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box, DialogActions,
    DialogTitle,
    Divider,
    IconButton
} from "@mui/material";
import { useEffect } from "react";
import { useMiddleware } from "../../../Application";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import TextField from "../../../components/TextField";
import useForm from "../../../utils/useForm";


const PricingFormDialog = ({
    open,
    onClose,
    defaultValues = {},
    onCreateOrUpdate,
    requestConfig
}) => {

    const middleware = useMiddleware('Pricing.Main');

    const initialValues = {
        name: '',
        ...defaultValues
    }

    const {
        register,
        reset,
        loading,
        handleSubmit
    } = useForm({
        initialValues,
        handlePost: data => middleware.createOrUpdate({ ...data }, requestConfig),
        onSubmit: onCreateOrUpdate
    })

    useEffect(() => {
        if (open) reset(initialValues)
    }, [open]);


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{defaultValues.id ? 'بروزرسانی تعرفه' : 'ثبت تعرفه جدید'}</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    size="small"
                    label="نام"
                    variant="filled"
                    required
                    {...register("name")}
                />
            </Box>
            <Divider />
            <DialogActions>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                >
                    ثبت
                </LoadingButton>
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>
        </ResponsiveDialog>
    )
}

export default PricingFormDialog