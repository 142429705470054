import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class BoardsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Boards';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/tasks/boards',
        createOrUpdate: '/tasks/boards',
        details: '/tasks/boards/:boardId',
    }

    /**
     * Get list of boards match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args));
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get details of the board
     * 
     * @param {string} boardId
     * @return Promise 
     */
    async details(boardId) {
        try {
            const response = await axios(this.replaceParams(this.paths.details, { boardId }));
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing board
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }
}

export default BoardsMiddleware;