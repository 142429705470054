import Main from "./main";
import Boards from "./boards";
import Lists from "./lists";
import Cards from "./cards";


const middlewares = [
    Main,
    Boards,
    Lists,
    Cards
];

export default middlewares;