import { TabsProvider } from "../tabs";
import TaskbarProvider from "../taskbar/TaskbarProvider";
import { TourProvider } from '../tour';
import Layout from "./AppLayout";


const LayoutContainer = () => {
    return (
        <TabsProvider>
            <TaskbarProvider>
                <TourProvider>
                    <Layout />
                </TourProvider>
            </TaskbarProvider>
        </TabsProvider>
    )
}

export default LayoutContainer;