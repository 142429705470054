import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    InputAdornment
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMiddleware } from "../../../Application";
import NumberTextField from "../../../components/NumberTextField";


const ContractPaymentFormDialog = ({
    open,
    onClose,
    contract,
    onSubmit,
    initialValues
}) => {

    const middleware = useMiddleware('transactions.contracts');

    const defaultValues = {
        amount: '',
        ...initialValues,
    }

    const { control, register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
        defaultValues
    });


    const [isCreatingOrUpdating, setCreatingOrUpdating] = useState(false);

    useEffect(() => {
        if (!open) {
            const timer = setTimeout(() => {
                reset(defaultValues);
            }, 500);

            return () => {
                clearTimeout(timer);
            }
        }
    }, [open])


    const { enqueueSnackbar } = useSnackbar();

    const _createOrUpdateAsync = async (data) => {
        setCreatingOrUpdating(true);
        const response = await middleware.addPayment(contract.id, data);
        if (response.ok) {
            enqueueSnackbar('پرداخت ثبت گردید.');
            onClose()
            onSubmit()
            setCreatingOrUpdating(false)
            reset()
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>
                پراخت جدید
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Controller
                    name="amount"
                    control={control}
                    roles={{ max: contract?.remaining }}
                    render={
                        ({ field }) => (
                            <NumberTextField
                                fullWidth={true}
                                size="small"
                                label="مبلغ"
                                dir="ltr"
                                autoFocus
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>
                                }}
                                required
                                {...register("amount", { required: true })}
                                error={errors.amount && true}
                                {...field}
                            />
                        )
                    }
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <LoadingButton
                    loading={isCreatingOrUpdating}
                    loadingPosition="start"
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit(_createOrUpdateAsync)}
                >
                    ثبت
                </LoadingButton>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ContractPaymentFormDialog;