import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/invoices/:label',
        getNewNumber: '/invoices/new/number',
        createOrUpdate: '/invoices/:label',
        details: '/invoice/:invoiceId',
        delete: '/invoice/:invoiceId',
    }

    /**
     * Get list of invoices match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(label, args) {
        try {
            const response = await axios(
                this.serializePath(
                    this.replaceParams(this.paths.list, { label }),
                    args
                )
            );

            return {
                ...response.data
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get new number for new creating invoice
     *  
     * @return Promise
     */
    async getNewNumber() {
        try {
            const response = await axios(this.paths.getNewNumber);
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing invoice
     *  
     * @return Promise
     */
    async createOrUpdate(label, data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.replaceParams(this.paths.createOrUpdate, { label }),
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {
            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Get details of an invoice
     *  
     * @return Promise
     */
    async details(invoiceId) {
        try {
            const response = await axios(this.replaceParams(this.paths.details, { invoiceId }));

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an invoice
     *  
     * @return Promise
     */
    async delete(invoiceId) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { invoiceId })
            });

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;