/* eslint-disable react-hooks/rules-of-hooks */
import SupervisorAccountOutlined from "@mui/icons-material/SupervisorAccountOutlined";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Fragment } from "react";
import Text from "../../../components/Text";
import useDialogProps from "../../../utils/useDialogProps";
import RoleSelectDialog from "../components/RoleSelectDialog";


const hooks = {
    'People': {
        'PersonForm.Hooks': injectedParams => ({
            'rolesDialogHook': useDialogProps()
        }),
        'PersonForm.PostForm': ({ getValue, rolesDialogHook, key }) => {
            const [rolesDialogProps, openRolesDialog, closeRolesDialog] = rolesDialogHook;

            return (
                <Fragment key={key}>
                    <Divider />
                    <List disablePadding dense>
                        <ListItem disablePadding dense>
                            <ListItemButton onClick={() => openRolesDialog()}>
                                <ListItemIcon>
                                    <SupervisorAccountOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    primary={getValue('roles') && getValue('roles').length > 0 ? getValue('roles').map(r => r.name).join(', ') : <small>برای انتخاب کلیک کنید</small>}
                                    secondary="نقش‌ها"
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Fragment>
            )
        },
        'PersonForm.initialValues': () => ({
            roles: []
        }),
        'PersonForm.Footer': ({ getValue, setValue, rolesDialogHook, key }) => {
            const [rolesDialogProps, openRolesDialog, closeRolesDialog] = rolesDialogHook;

            return (
                <Fragment key={key}>
                    <RoleSelectDialog
                        {...rolesDialogProps}
                        selectable
                        multiple
                        selected={getValue("roles") || []}
                        onClear={() => setValue("roles", [])}
                        onSelect={role => setValue(
                            "roles",
                            getValue("roles").filter(c => c.id === role.id).length > 0 ?
                                getValue("roles").filter(c => c.id !== role.id) :
                                [...getValue("roles"), role]
                        )}
                    />
                </Fragment>
            )
        },
        'PeopleList.definitions': () => ({
            title: 'نقش‌ها',
            render: row => row.roles && row.roles.length > 0 ? <Text>{row.roles.map(role => role.name).join(', ')}</Text> : '-',
        }),
        'PersonDetails.Overview.DataList': ({ person, loading }) => ({
            title: 'نقش‌ها',
            description: person.roles ? <Text>{person.roles.map(c => c.name).join(', ')}</Text> : '-'
        }),
    },
};

export default hooks;