/* eslint-disable react-hooks/rules-of-hooks */


import ShoppingBagOutlined from "@mui/icons-material/ShoppingBagOutlined";
import { lazy } from "react";

const OnlineShopSettings = lazy(() => import('../pages/Settings'));


const Core = {
    'routes.settings': () => {
        return {
            variant: "dashboard",
            private: true,
            path: 'online-store',
            element: <OnlineShopSettings />
        }
    },
    'Settings.TabsList': ({ params }) => {
        return {
            label: 'فروشگاه آنلاین',
            value: `/settings/online-store`,
            icon: <ShoppingBagOutlined />
            // priority: 50,
        }
    },
}

export default Core;