import { useEffect, useState } from "react";
import Indicator from "./Indicator";


const LoadableData = ({ loadData, getData, onLoadData, renderData }) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const loadDataAsync = async () => {
        const response = await loadData()
        if (response.ok) {
            setData(getData ? getData(response) : response);
            setLoading(false);
            onLoadData && onLoadData(response);
        }
    }

    useEffect(() => {
        loadDataAsync()
    }, [loadData]);


    if (loading) {
        return <Indicator />
    }

    return renderData ? renderData(data) : data;
}

export default LoadableData