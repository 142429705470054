import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware name
     *
     * @var string
     */
    basePath = '/products';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        createOrUpdate: '/',
        getNewCode: '/new/code',
        details: '/:productId',
        delete: '/:productId',
    }

    /**
     * Get list of products match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(
                this.serializePath(this.paths.list, args)
            );

            return {
                ...response.data
            }
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Get new code for new creating product
     *  
     * @return Promise
     */
    async getNewCode() {
        try {
            const response = await axios(this.paths.getNewCode);
            return response.data;
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Create or update an existing product
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            console.log(error);
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }

    /**
     * Get product details
     * 
     * @param string productId 
     * @return Promise 
     */
    async details(productId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.details, { productId })
            );
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an existing product from db
     *  
     * @return Promise
     */
    async delete(productId) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { productId })
            });
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;