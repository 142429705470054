import WorkspacesMiddleware from "./WorkspacesMiddleware";
import ModulesMiddleware from "./ModulesMiddleware";
import AuthenticatedMiddleware from "./AuthenticatedMiddleware";
import SubscriptionsMiddleware from "./SubscriptionsMiddleware";
import MembershipMiddleware from "./MembershipMiddleware";
import WorkspaceMiddleware from "./WorkspaceMiddleware";


const middlewares = [
    WorkspacesMiddleware,
    ModulesMiddleware,
    AuthenticatedMiddleware,
    SubscriptionsMiddleware,
    MembershipMiddleware,
    WorkspaceMiddleware,
];

export default middlewares;