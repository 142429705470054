import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/auth';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        verify: '/verify',
        login: '/login',
        loginUsingSMS: '/login/sms',
        sendCode: '/sendCode',
    }

    /**
     * Verify user
     * 
     * @param {string} phone user phone number
     * @returns response
     */
    async verify(phone) {
        try {
            const response = await axios.post(this.paths.verify, { phone });
            return {
                ...response.data
            }
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }

    /**
     * Login user with phone and password
     * 
     * @param {Object} data data provided
     * @param {string} data.phone user phone number
     * @param {string} data.password user password 
     * @returns response
     */
    async login(data) {
        try {
            const response = await axios.post(this.paths.login, data);
            return {
                ...response.data
            }
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }

    /**
     * Login user with phone and password
     * 
     * @param {Object} data data provided
     * @param {string} data.phone user phone number
     * @param {string} data.code user code 
     * @returns response
     */
    async loginUsingSMS(data) {
        try {
            const response = await axios.post(this.paths.loginUsingSMS, data);
            return {
                ...response.data
            }
        } catch (error) {
            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Send code to phone number provided by user
     * 
     * @param {string} phone Phone number provided
     * @returns response
     */
    async sendCode(phone) {
        const response = await axios.post(this.paths.sendCode, { phone });
        return {
            ...response.data
        }
    }
}

export default MainMiddleware;