import AdapterJalali from '@date-io/date-fns-jalali';
import CloseIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button, DialogActions,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Tab,
    Tabs,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { useComponent, useMiddleware } from '../../../Application';
import NumberTextField from "../../../components/NumberTextField";
import ResponsiveDialog from '../../../components/ResponsiveDialog';
import TextField from '../../../components/TextField';
import useDialogProps from '../../../utils/useDialogProps';
import useForm from '../../../utils/useForm';


const TransactionFormDialog = ({
    open,
    onClose,
    transaction,
    onCreateOrUpdate,
    defaultValues = {}
}) => {

    const middleware = useMiddleware('transactions.main');

    const PersonSelectDialog = useComponent('People.PersonSelectDialog');

    const initialValues = {
        client: '',
        amount: '',
        type: 'RECEIVEABLE',
        note: '',
        paidAt: null,
        ...defaultValues,
    }

    const {
        register,
        loading,
        handleSubmit,
        reset,
        getValue,
        setValue
    } = useForm({
        initialValues,
        handlePost: data => middleware.createOrUpdate(data),
        onSubmit: onCreateOrUpdate
    })

    useEffect(() => {
        if (open) reset(initialValues);
    }, [open]);

    const [clientDialogProps, openClientDialog, closeClientDialog] = useDialogProps();


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{defaultValues.id ? 'بروزرسانی رویداد مالی' : 'ثبت رویداد مالی'}</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 0 }}>
                {!transaction ? (
                    <Box sx={{ mb: 2 }}>
                        <Tabs
                            variant="fullWidth"
                            value={getValue("type")}
                            onChange={(e, value) => setValue("type", value)}
                        >
                            <Tab label="پرداخت" value="RECEIVEABLE" />
                            <Tab label="دریافت" value="PAYABLE" />
                        </Tabs>
                        <Divider sx={{ mt: '-1px' }} />
                    </Box>
                ) : null}
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        {!defaultValues.client && (
                            <Grid item xs={12}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    fullWidth={true}
                                    size="small"
                                    label="مشتری"
                                    value={getValue("client") ? getValue("client").title : ''}
                                    onChange={() => { }}
                                    required
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    onFocus={() => openClientDialog()}
                                    onClick={() => openClientDialog()}
                                    onKeyDown={e => e.keyCode === 13 && openClientDialog()}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={7}>
                            <NumberTextField
                                fullWidth={true}
                                size="small"
                                variant="filled"
                                label="مبلغ"
                                dir="ltr"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>
                                }}
                                required
                                {...register("amount")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <LocalizationProvider dateAdapter={AdapterJalali}>
                                <DateTimePicker
                                    mask="____/__/__ __:__"
                                    label="تاریخ و ساعت پرداخت"
                                    renderInput={
                                        (params) => (
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth={true}
                                                variant="filled"
                                                size="small"
                                                {...params}
                                            />
                                        )
                                    }
                                    value={getValue("paidAt")}
                                    onChange={value => setValue("paidAt", value)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth={true}
                                variant="filled"
                                size="small"
                                label="بابت"
                                {...register("note")}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Divider />
            <DialogActions>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                >
                    ثبت مشخصات
                </LoadingButton>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>انصراف</Button>
            </DialogActions>

            <PersonSelectDialog
                {...clientDialogProps}
                roles={["client"]}
                selected={getValue("client")}
                onSelect={client => {
                    setValue("client", client);
                    closeClientDialog()
                }}
                onClear={() => {
                    setValue("client", '')
                    closeClientDialog()
                }}
            />
        </ResponsiveDialog>
    )
}

export default TransactionFormDialog