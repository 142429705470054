import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from "@mui/icons-material/CloseOutlined";
import {
    Box,
    Button, DialogActions, DialogTitle, Divider, IconButton, List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from "react";
import { useMiddleware } from "../../../Application";
import { useAuth } from '../../../auth';
import ResponsiveDialog from '../../../components/ResponsiveDialog';


const PersonRulesSelectDialog = ({
    open,
    onClose,
    defaultValue,
    onChange,
    multiple
}) => {

    const { inject, user } = useAuth();

    const injectedRules = inject('People', 'PersonForm.Roles');

    const [value, setValue] = useState(defaultValue || (multiple ? [] : ''));

    const [loading, setLoading] = useState(false);
    const [roles, setRules] = useState([]);

    const middleware = useMiddleware('Metas.Main');

    const loadRulesAsync = async () => {
        setLoading(true);
        const response = await middleware.list("role");
        if (response.ok) {

            const rulesArray = [
                ...response.data,
                ...(injectedRules || []),
                user.currentWorkspace.category?.identifier !== "personal" && { identifier: 'admin', name: "مدیر" }
            ].filter(Boolean);

            const filteredRules = [
                ...rulesArray.filter(r => !r.identifier),
                ...uniqBy(rulesArray.filter(r => r.identifier), r => r.identifier)
            ];
            
            setRules(filteredRules);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open) {
            setValue(defaultValue);
            loadRulesAsync();
        }
    }, [open]);


    const isEqual = (option, role) => option.id && role.id ? option.id === role.id : option.identifier === role.identifier;
    const isSelected = role => multiple ? value.filter(v => isEqual(v, role)).length > 0 : isEqual(value, role)
    const handleChange = (role) => {
        const newValue = (
            multiple ? (
                value.filter(s => isEqual(s, role)).length > 0 ?
                    value.filter(s => !isEqual(s, role)) :
                    [...value, role]
            ) : role
        );
        setValue(newValue);
        onChange(newValue);
    }


    return (
        <ResponsiveDialog
            onClose={onClose}
            open={open}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>سمت‌های شخص</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <List sx={{ pt: 0 }} disablePadding>
                {roles.map((role) => (
                    <ListItem key={role.identifier} disablePadding>
                        <ListItemButton
                            selected={isSelected(role)}
                            onClick={() => handleChange(role)}
                        >
                            <ListItemIcon>
                                {isSelected(role) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                            </ListItemIcon>
                            <ListItemText primary={<strong>{role.name}</strong>} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={onClose}
                >
                    ثبت و بستن
                </Button>
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>
        </ResponsiveDialog>
    )
}

export default PersonRulesSelectDialog;