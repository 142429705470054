import { Icon as Iconify } from "@iconify/react";



const tasks = {
    submitVisit: {
        title: `ثبت مراجعه`,
        Icon: (props) => <Iconify {...props} icon='solar:restart-circle-bold-duotone' />,
        path: `/visit`,
        keywords: [],
        default: true,
        subtasks: []
    },
    welcome: {
        title: `خوش‌آمدگویی`,
        Icon: (props) => <Iconify {...props} icon='solar:hand-shake-bold-duotone' />,
        path: `/welcome`,
        keywords: [],
        default: true,
        subtasks: []
    },
    birthday: {
        title: `تبریک تولد`,
        Icon: (props) => <Iconify {...props} icon='twemoji:birthday-cake' />,
        path: `/birthday`,
        keywords: [],
        default: true,
        subtasks: []
    },
    campaigns: {
        title: `ارسال گروهی`,
        Icon: (props) => <Iconify {...props} icon='solar:multiple-forward-right-bold-duotone' />,
        path: `/campaigns`,
        keywords: [],
        default: true,
        subtasks: []
    },
    loyaltyPrograms: {
        title: `برنامه وفاداری`,
        Icon: (props) => <Iconify {...props} icon='solar:user-heart-bold-duotone' />,
        path: `/loyalty-programs`,
        keywords: [],
        default: true,
        subtasks: []
    },
    review: {
        title: `نظرسنجی`,
        Icon: (props) => <Iconify {...props} icon='solar:user-speak-bold-duotone' />,
        path: `/review`,
        keywords: [],
        default: true,
        subtasks: []
    },
    reward: {
        title: `جایزه`,
        Icon: (props) => <Iconify {...props} icon='solar:gift-bold-duotone' />,
        path: `/reward`,
        keywords: [],
        default: true,
        subtasks: []
    },
    direct: {
        title: `لینک دایرکت`,
        Icon: (props) => <Iconify {...props} icon='solar:link-round-angle-bold-duotone' />,
        path: `/direct`,
        keywords: [],
        default: true,
        subtasks: []
    },
    wheel: {
        title: `گردونه شانس`,
        Icon: (props) => <Iconify {...props} icon='solar:wheel-bold-duotone' />,
        path: `/wheel`,
        keywords: [],
        default: true,
        subtasks: []
    },
    winner: {
        title: `برنده باش`,
        Icon: (props) => <Iconify {...props} icon='solar:wheel-bold-duotone' />,
        path: `/winner`,
        keywords: [],
        default: true,
        subtasks: []
    },
    retarget: {
        title: `هدفگیری مجدد`,
        Icon: (props) => <Iconify {...props} icon='solar:wheel-bold-duotone' />,
        path: `/retarget`,
        keywords: [],
        default: true,
        subtasks: []
    },
    notification: {
        title: `اطلاع‌رسانی`,
        Icon: (props) => <Iconify {...props} icon='solar:wheel-bold-duotone' />,
        path: `/notification`,
        keywords: [],
        default: true,
        subtasks: []
    },
    invoice: {
        title: `صورت‌حساب`,
        Icon: (props) => <Iconify {...props} icon='solar:wheel-bold-duotone' />,
        path: `/invoice`,
        keywords: [],
        default: true,
        subtasks: []
    },
    formBuilder: {
        title: `فرم‌ساز`,
        Icon: (props) => <Iconify {...props} icon='solar:wheel-bold-duotone' />,
        path: `/form-builder`,
        keywords: [],
        default: true,
        subtasks: []
    },
    reminder: {
        title: `یادآوری`,
        Icon: (props) => <Iconify {...props} icon='solar:wheel-bold-duotone' />,
        path: `/reminder`,
        keywords: [],
        default: true,
        subtasks: []
    },
}

export default tasks;