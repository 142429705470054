import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Campaigns';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/campaigns';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        createOrUpdate: '/',
    }

    /**
     * Get list of persons match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args));
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing person
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }
}

export default MainMiddleware;