/* eslint-disable react-hooks/rules-of-hooks */
// import Add from "@mui/icons-material/Add";
// import ArrowBack from "@mui/icons-material/ArrowBack";
// import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
// import PhotoLibraryOutlined from "@mui/icons-material/PhotoLibraryOutlined";
import { Avatar, Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { Icon as Iconify } from "@iconify/react";

import { useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import useDialogProps from "../../../utils/useDialogProps";
import FileSelectDialog from "../components/FileSelectDialog";


const CategoryMediaDialog = ({ open, onClose, defaultValue = [], onConfirm }) => {

    const middleware = useMiddleware('Storage.Main');
    const { workspace, getAuthToken } = useAuth();

    const [value, setValue] = useState(defaultValue);

    const [fileSelectDialogProps, setFileSelectDialogProps] = useState({ open: false });


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>مدیریت رسانه‌ها</Box>
                <IconButton onClick={onClose} size="small">
                    <Iconify icon='solar:arrow-left-linear' />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 0 }}>
                <Box sx={{ p: 2 }}>
                    <Typography fontWeight={700} sx={{ mb: 2 }}>تصاویر</Typography>
                    <Grid container spacing={1}>
                        {value.filter(m => !m.intro).map((media, idx) => (
                            <Grid item xs="auto" key={idx}>
                                <Box sx={theme => ({ position: 'relative', borderRadius: theme.shape.borderRadius, overflow: 'hidden' })}>
                                    <Avatar
                                        key={idx}
                                        src={middleware.getDirectDownloadUrl(media.file.id, getAuthToken(), workspace.id)}
                                        sx={{ width: 64, height: 64 }}
                                        variant="rounded"
                                    />
                                    <Box
                                        sx={theme => ({
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(0,0,0,0.5)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#fff',
                                            opacity: 0,
                                            transition: theme.transitions.create('opacity'),
                                            cursor: 'pointer',
                                            '&:hover': {
                                                opacity: 1
                                            }
                                        })}
                                        onClick={() => setValue(value.filter(m => m.file.id !== media.file.id))}
                                    >
                                        <Iconify icon='solar:trash-bin-2-linear' />
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                        <Grid item xs={6} sm={4} md={3}>
                            <Avatar
                                sx={theme => ({
                                    position: 'relative',
                                    width: 64,
                                    height: 64,
                                    background: 'transparent',
                                    border: '1px dashed',
                                    borderColor: 'divider',
                                    color: 'divider',
                                    cursor: 'pointer',
                                    transition: theme.transitions.create('all'),
                                    '&:hover': {
                                        borderStyle: 'solid',
                                    }
                                })}
                                variant="rounded"
                                onClick={() => setFileSelectDialogProps({
                                    open: true,
                                    multiple: true,
                                    selected: value.filter(m => !m.intro).map(m => m.file),
                                    // onClear: () => setValue(value.filter(m => !m.intro)),
                                    onSelect: file => setValue(
                                        value.filter(c => c.file.id === file.id).length > 0 ?
                                            value.filter(c => c.file.id !== file.id) :
                                            [...value, { file }]
                                    )
                                })}
                            >
                                <Iconify icon='solar:add-circle-linear' />
                            </Avatar>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                {onConfirm && (
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => onConfirm(value)}
                    >
                        تایید و بستن
                    </Button>
                )}
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>بستن</Button>
            </DialogActions>

            <FileSelectDialog
                {...fileSelectDialogProps}
                onClose={() => setFileSelectDialogProps({ open: false })}
                selectable
            // multiple
            // selected={value}
            // onClear={() => setValue([])}
            // onSelect={file => setValue(
            //     value.filter(c => c.id === file.id).length > 0 ?
            //         value.filter(c => c.id !== file.id) :
            //         [...value, file]
            // )}
            />

        </ResponsiveDialog>
    )
}

const categories = {
    'CategoriesList.definitions': () => {
        const { getAuthToken, workspace } = useAuth();
        const middleware = useMiddleware('Storage.Main');

        return ({
            title: 'تصویر',
            render: row => (
                <Avatar
                    variant="rounded"
                    alt={row?.name}
                    src={row?.media && row?.media.length > 0 ? middleware.getDirectDownloadUrl(row?.media[0].id, getAuthToken(), workspace.id) : null}
                    sx={{ width: 48, height: 48 }}
                    
                >
                    <Iconify icon='solar:box-bold-duotone'/>
                </Avatar>
            ),
            priority: 150,
        });
    },
    'CategoryForm.initialValues': () => ({
        media: []
    }),
    'CategoryForm.Hooks': injectedParams => ({
        'mediaDialogHook': useDialogProps(),
    }),
    'CategoryForm.PostForm': ({ mediaDialogHook, key }) => {
        const [mediaDialogProps, openMediaDialog, closeMediaDialog] = mediaDialogHook;


        return (
            <Box key={key}>
                <Divider />
                <List disablePadding dense>
                    <ListItem disablePadding dense>
                        <ListItemButton onClick={() => openMediaDialog()}>
                            <ListItemIcon>
                                <Iconify icon='solar:gallery-wide-linear' fontSize="1.5em" />
                            </ListItemIcon>
                            <ListItemText
                                primary={<small>برای انتخاب کلیک کنید</small>}
                                secondary="رسانه‌ها"
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        )
    },
    'CategoryForm.Footer': ({ getValue, setValue, mediaDialogHook, key }) => {
        const [mediaDialogProps, openMediaDialog, closeMediaDialog] = mediaDialogHook;

        return (
            <Fragment key={key}>
                <CategoryMediaDialog
                    defaultValue={getValue('media')}
                    onConfirm={media => {
                        setValue('media', media);
                        closeMediaDialog();
                    }}
                    {...mediaDialogProps}
                />
            </Fragment>
        )
    },
}

export default categories;