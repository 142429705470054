import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    Grid,
    TextField
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";


const AddressFormDialog = ({
    open,
    onClose,
    address,
    onSubmit
}) => {

    const defaultValues = {
        city: '',
        area: '',
        street: '',
        address: ''
    }

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues
    });

    useEffect(() => {
        if (address) reset(address);
    }, [address]);

    const _submit = (data) => {
        onSubmit(data);
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                ثبت آدرس
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <Box sx={{ pb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth={true}
                                size="small"
                                label="شهر"
                                error={errors.city && true}
                                {...register("city", { required: true })}
                                autoFocus
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth={true}
                                size="small"
                                label="منطقه"
                                {...register("area")}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth={true}
                                size="small"
                                label="خیابان"
                                {...register("street")}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth={true}
                                size="small"
                                label="آدرس"
                                error={errors.address && true}
                                {...register("address", { required: true })}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Divider />
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit(_submit)}
                >
                    ثبت
                </Button>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddressFormDialog