import { List, ListItem, ListItemText, Skeleton } from "@mui/material";


const ListLoader = ({ listProps = {}, listItemProps = {}, length = 5 }) => {
    return (
        <List {...listProps}>
            {[...Array(length)].map((a, i) => (
                <ListItem {...listItemProps} key={i} divider>
                    <ListItemText
                        primary={<Skeleton variant="text" width="50%" />}
                        secondary={<Skeleton variant="text" />}
                    />
                </ListItem>
            ))}
        </List>
    )
}

export default ListLoader;