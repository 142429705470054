import axios, { AxiosError } from "axios";
import Middleware from "../../../foundation/Middleware";

class InvoicesMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Invoices';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        transactions: '/invoice/:invoiceId/trasactions',
        isSettled: '/invoice/:invoiceId/isSettled',
        totalPaid: '/invoice/:invoiceId/totalPaid',
        remaining: '/invoice/:invoiceId/remaining',
        settle: '/invoice/:invoiceId/settle',
        addPayment: '/invoice/:invoiceId/payments',
        //
    }

    /**
     * Display a list of all transactions of invoice
     *  
     * @return Promise
     */
    async transactions(invoiceId, args) {
        try {
            const response = await axios(
                this.serializePath(
                    this.replaceParams(this.paths.transactions, { invoiceId }),
                    args
                )
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get invoice settled status
     *  
     * @return Promise
     */
    async isSettled(invoiceId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.isSettled, { invoiceId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get invoice total paid
     *  
     * @return Promise
     */
    async totalPaid(invoiceId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalPaid, { invoiceId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get invoice settled status
     *  
     * @return Promise
     */
    async remaining(invoiceId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.remaining, { invoiceId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Settle all transactions of a invoice
     *  
     * @return Promise
     */
    async settle(invoiceId) {
        try {
            const response = await axios.post(
                this.replaceParams(this.paths.settle, { invoiceId })
            );
            return { ...response.data };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Add new payment for the invoice
     *  
     * @return Promise
     */
    async addPayment(invoiceId, data) {
        try {
            const response = await axios.post(
                this.replaceParams(this.paths.addPayment, { invoiceId }),
                data
            );
            return { ...response.data };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    //
}

export default InvoicesMiddleware;