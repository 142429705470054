import { lazy } from "react";
import Loadable from "../../components/Loadable";

// global roues
const SubscriptionsContainer = Loadable(lazy(() => import('./pages/Subscriptions/Main')));
const Subscriptions = lazy(() => import('./pages/Subscriptions/Subscriptions'));
const Settings = Loadable(lazy(() => import('./pages/Settings/Settings')));
const GeneralSettings = lazy(() => import('./pages/Settings/General'));

// const FarahubDocs = lazy(() => import('../documentations/pages/FarahubDocs/Main'));

// Admin specific routes
const Workspaces = Loadable(lazy(() => import('./pages/Workspaces/Workspaces')));
// const Marketplace = Loadable(lazy(() => import('./pages/Marketplace/Main')));
const Modules = Loadable(lazy(() => import('./pages/Modules/Modules')));
const WorkspaceDetails = Loadable(lazy(() => import('./pages/WorkspaceDetails/WorkspaceDetails')));
const WorkspaceDetailsOverview = lazy(() => import('./pages/WorkspaceDetails/Overview'));
const WorkspaceDetailsSubscriptions = lazy(() => import('./pages/WorkspaceDetails/Subscriptions'));


const routes = module => ([
	{
		variant: "dashboard",
		private: true,
		path: '/settings',
		element: <Settings />,
		children: [
			{ variant: "dashboard", private: true, index: true, element: <GeneralSettings /> },
			...(module.inject('routes.settings') || [])
		],
		// authorize: ({ hasRole, hasPermission }) => {
		// 	return hasRole("owner") || hasPermission("person:view");
		// }
	},
	{
		variant: "dashboard",
		private: true,
		path: '/subscriptions',
		element: <SubscriptionsContainer />,
		authorize: ({ hasRole, hasPermission }) => {
			return hasRole('owner') || hasPermission('core:subscriptions:view');
		},
		children: [
			{ variant: "dashboard", private: true, index: true, element: <Subscriptions /> },
			// { variant: "dashboard", private: true, path: "marketplace", element: <Marketplace /> }
		]
	},
	// {
	// 	variant: "dashboard",
	// 	path: '/marketplace',
	// 	element: <Marketplace />,
	// 	private: true,
	// 	authorize: ({ hasRole, hasPermission }) => {
	// 		return hasRole('owner') || hasPermission('core:subscriptions:subscribe');
	// 	}
	// },
	{
		variant: "dashboard",
		path: '/workspaces',
		element: <Workspaces />,
		private: true,
		authorize: ({ workspace, hasRole, hasPermission }) => {
			if (workspace.identifier === 'farahub-admin')
				return hasRole('owner') || hasPermission('core:workspaces:view');

			return false;
		}
	},
	{
		variant: "dashboard",
		private: true,
		path: '/workspace/:workspaceId',
		element: <WorkspaceDetails />,
		children: [
			{ variant: "dashboard", private: true, index: true, element: <WorkspaceDetailsOverview /> },
			{ variant: "dashboard", private: true, path: "subscriptions", element: <WorkspaceDetailsSubscriptions /> },
		],
		authorize: ({ workspace, hasRole, hasPermission }) => {
			if (workspace.identifier === 'farahub-admin')
				return hasRole('owner') || hasPermission('core:workspaces:view');

			return false;
		}
	},
	{
		variant: "dashboard",
		private: true,
		path: '/modules',
		element: <Modules />,
		authorize: ({ workspace, hasRole, hasPermission }) => {
			if (workspace.identifier === 'farahub-admin')
				return hasRole('owner') || hasPermission('core:modules:view');

			return false;
		}
	},
	// {
	// 	variant: "dashboard",
	// 	private: true,
	// 	path: '/support/docs',
	// 	element: <FarahubDocs />,
	// },
]);

export default routes;