import { useState } from "react";
import ApplicationContext from "./ApplicationContext";
import Application from "../foundation/Application";
import config from "./config";


const ApplicationProvider = ({ children }) => {

    const [application] = useState(new Application(config));

    return (
        <ApplicationContext.Provider value={{ application }}>
            {children}
        </ApplicationContext.Provider>
    )
}

export default ApplicationProvider;