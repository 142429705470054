import SearchIcon from "@mui/icons-material/SearchOutlined";
import NoResultItem from "@mui/icons-material/WarningOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    Box,
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogTitle, Divider, IconButton, InputAdornment, Stack, styled, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Tooltip
} from "@mui/material";
import { useEffect, useState } from "react";
import useDebounce from '../../../utils/useDebounce';
import useDialog from '../../../utils/useDialog';
import CategoryFormDialog from "./CategoryFormDialog";
import CategoryDeleteDialog from "./CategoryDeleteDialog";
import { useMiddleware } from "../../../Application";
import TextField from "../../../components/TextField";


const StyledTableRow = styled(TableRow)(({ theme, hover, selected }) => ({
    cursor: hover ? 'pointer' : 'auto',
    backgroundColor: selected ? theme.palette.action.hover : theme.palette.common.white,
    '&:focus': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CategoriesTable = ({
    loading,
    categories,
    selected,
    onSelect,
    selectable,
    onClickEdit,
    onClickDelete
}) => {

    if (loading) {
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <CircularProgress />
            </Box>
        )
    }

    if (categories.length < 1) {
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <NoResultItem fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )
    }

    return (
        <TableContainer sx={{ overflow: "auto", maxHeight: 400 }}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell>گروه</TableCell>
                        {!selectable && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map((category, index) => (
                        <StyledTableRow
                            key={index}
                            selected={Boolean(selectable && selected && selected.id === category.id)}
                            hover={selectable}
                            onClick={() => selectable && onSelect(category)}
                        >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell>{category.name}</TableCell>
                            {!selectable && (
                                <TableCell align="center">
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        justifyContent="flex-end"
                                    >
                                        <Tooltip title="ویرایش گروه">
                                            <IconButton
                                                size="small"
                                                onClick={() => onClickEdit(category)}
                                            >
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="حذف گروه">
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={() => onClickDelete(category)}
                                            >
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </TableCell>
                            )}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    )
}

const CategoriesDialog = ({
    label,
    open,
    onClose,
    selectable,
    selected,
    onSelect,
    onClear
}) => {

    const middleware = useMiddleware('categories.main');

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    const [query, setQuery] = useState('');


    const debouncedQuery = useDebounce(query, 500);

    const _loadCategoriesAsync = async () => {
        const { ok, data } = await middleware.list({
            label,
            query: debouncedQuery,
            ...(query === '' ? {
                page: 0,
                perPage: 100
            } : {})
        });

        if (ok) {
            setCategories(data);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open) {
            _loadCategoriesAsync();
        } else {
            const timer = setTimeout(() => {
                setLoading(true);
                if (query !== '') setQuery('');
            }, 500);

            return () => {
                clearTimeout(timer);
            }
        }
    }, [open, debouncedQuery]);


    const {
        isOpen: isFormDialogOpen,
        isOpening: isFormDialogOpening,
        open: openFormDialog,
        close: closeFormDialog,
        data: formDialogCategory
    } = useDialog();

    const {
        isOpen: isDeleteDialogOpen,
        isOpening: isDeleteDialogOpening,
        open: openDeleteDialog,
        close: closeDeleteDialog,
        data: deleteDialogCategory
    } = useDialog();


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>
                {selectable ? 'انتخاب گروه' : 'مدیریت گروه‌ها'}
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    size="small"
                    variant="filled"
                    label="جستجو براساس نام"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    autoFocus
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                    }}
                />
            </Box>
            <CategoriesTable
                loading={loading}
                categories={categories}
                selectable={selectable}
                selected={selected}
                onSelect={onSelect}
                onClickEdit={category => openFormDialog(category)}
                onClickDelete={category => openDeleteDialog(category)}
            />
            <Divider />
            <DialogActions>
                <Button
                    type="button"
                    onClick={() => openFormDialog()}
                    disabled={isFormDialogOpening}
                    variant="contained"
                >
                    ثبت جدید
                </Button>
                {onClear && <Button type="button" onClick={onClear}>پاک کردن</Button>}
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>انصراف</Button>
            </DialogActions>

            <CategoryFormDialog
                label={label}
                open={isFormDialogOpen}
                onClose={closeFormDialog}
                category={formDialogCategory}
                onCreateOrUpdate={category => _loadCategoriesAsync()}
            />

            <CategoryDeleteDialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
                category={deleteDialogCategory}
                onDelete={() => _loadCategoriesAsync()}
            />
        </Dialog>
    )
}

export default CategoriesDialog