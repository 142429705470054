import { Button, InputBase, Stack, styled } from "@mui/material";


const Wrapper = styled(
    props => <Stack direction="row" spacing={1} {...props} />
)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid `.concat(theme.palette.grey[400]),

    '&:hover': {
        borderColor: theme.palette.grey[600]
    }
}));

const Field = styled(InputBase)(({ theme }) => ({
    flexGrow: 1
}));

const ActionsWrapper = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "column"
}));

const SmsField = ({ size, value, onChange, ...props }) => {
    return (
        <Wrapper>
            <Field
                multiline
                minRows={4}
                margin="none"
                value={value}
                onChange={e => onChange(e.target.value)}
                sx={{ height: '100%', padding: 0 }}
                {...props}
            />
            <ActionsWrapper spacing={0.5}>
                <Button
                    variant="contained"
                    size="small"
                    onClick={e => onChange((value || '').concat('asdasd'))}
                >
                    نظرسنجی
                </Button>
                <Button variant="contained" size="small">صورت‌حساب</Button>
                <Button variant="contained" size="small">ایموجی</Button>
                <Button variant="contained" size="small">لینک دایرکتی</Button>
            </ActionsWrapper>
        </Wrapper>
    )
}


export default SmsField;