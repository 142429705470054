import omit from "lodash/omit";
import { useEffect, useState } from "react";

const useFetch = ({
    handler = () => { },
    deps = [],
    initialState = { data: {}, loading: false }
}) => {

    const [data, setData] = useState(initialState.data);
    const [loading, setLoading] = useState(initialState.loading);

    const loadDataAsync = async (loadingState = false) => {
        if (loadingState && !loading) setLoading(true);
        const response = await handler();
        if (response.ok) {
            setData(omit(response, 'ok'));
            setLoading(false);
        }
    }

    useEffect(() => {

        const timer = setTimeout(() => {
            loadDataAsync();
        }, [1000])

        return () => {
            clearTimeout(timer);
        }
    }, deps);

    const reload = (loadingState = false) => loadDataAsync(loadingState);

    return { data, loading, reload };
}

export default useFetch;