import { useSearchParams } from "react-router-dom";


const useGetRowNumber = (defaultPage = 0, defaultPerPage = 25) => {

    const [searchParams] = useSearchParams();

    const page = searchParams.has('page') ? Number(searchParams.get('page')) : defaultPage;
    const perPage = searchParams.has('perPage') ? Number(searchParams.get('perPage')) : defaultPerPage;

    return index => (perPage * page) + (index + 1);
}

export default useGetRowNumber;