import omit from "lodash/omit";
import { useEffect, useState } from "react";
import useAfterFirstRender from "./useAfterFirstRender";


const useResponsiveTableFetch = ({
    handleFetch,
    loadDeps = [],
    infiniteScrollDefaultParams = {},
    shouldLoadData = true
}) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        data: [],
        total: ''
    });

    const loadDataAsync = async (loadingState = false) => {
        if (loadingState && !loading) setLoading(true);
        const response = await handleFetch({
            page: 0,
            perPage: 25
        });
        if (response.ok) {
            setData(omit(response, ['ok']));
            setLoading(false);
        }
    }

    useEffect(() => {
        if (shouldLoadData)
            loadDataAsync();
    }, loadDeps);

    const [infiniteScrollParams, setInfiniteScrollParams] = useState({
        page: 0,
        perPage: 25,
        ...infiniteScrollDefaultParams
    });
    const loadMoreDataAsync = async () => {
        const response = await handleFetch(infiniteScrollParams);
        if (response.ok) {
            setData({
                ...omit(response, ['ok']),
                data: [...data.data, ...response.data]
            });
        }
    }

    useAfterFirstRender(() => {
        loadMoreDataAsync();
    }, [infiniteScrollParams]);

    const loadMore = () => setInfiniteScrollParams({
        ...infiniteScrollParams,
        infiniteScrollPage: infiniteScrollParams.infiniteScrollPage + 1
    })

    const reload = (loadingState = false) => loadDataAsync(loadingState);

    return {
        ...data,
        loading,
        loadMore,
        reload,
    }
}

export default useResponsiveTableFetch