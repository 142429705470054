import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class VisitMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Visit';

    /**
     * The middleware base path
     * 
     * @var string
     */
    basePath = '/visit';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        check: '/check',
        visit: '/',
    }

    /**
     * Check person exists and details using phone
     *  
     * @return Promise
     */
    async check(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.check,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            console.log(error);
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }

    /**
     * Store new visit for person
     *  
     * @return Promise
     */
    async store(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.visit,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            console.log(error);
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }
}

export default VisitMiddleware;