import AutoModeOutlined from "@mui/icons-material/AutoModeOutlined";

const tasks = {
    manageTasks: {
        title: `سناریوها`,
        Icon: AutoModeOutlined,
        path: '/tasks',
        keywords: [],
    },
    //
}

export default tasks;