import { lazy } from "react";
import Loadable from "../../components/Loadable";

const ManageStorage = Loadable(lazy(() => import('./pages/ManageStorage/Main')));
const FileManager = Loadable(lazy(() => import('./pages/FileManager/Main')));


const routes = [
    {
        variant: "dashboard",
        path: '/storage/manage',
        element: <ManageStorage />,
        private: true,
        authorize: ({ hasRole, hasPermission }) => {
            return hasRole('owner') || hasPermission('storage:view');
        }
    },
    {
        variant: "dashboard",
        path: '/storage/files',
        element: <FileManager />,
        private: true,
        authorize: ({ hasRole, hasPermission }) => {
            return hasRole('owner') || hasPermission('storage:view');
        }
    },

    //
];

export default routes;